import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, List } from "@mui/material";
const Sidebar = () => {
  const [open, setOpen] = React.useState(true);
  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(true);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const role = localStorage.getItem("role");

  useEffect(
    () => {
      handleClick();
      handleClick1();
      handleClick2();
      handleClick3();
    },
    // eslint-disable-next-line
    []
  );
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };

  return (
    <>
      <div className="flex flex-col p-px h-[91vh] w-[295px] justify-between border-r overflow-y-auto border-darkborder overflow-hidden">
        <div className="flex flex-col text-zinc-200">
          {/* {role === "admin" ? ( */}
            <List className="flex flex-col">
              <Link
                to={"/dashboard"}
                className={classNames(
                  "p-2 px-3 text-white-100 text-sm  m-2 w-52",
                  window.location.pathname === "/dashboard"
                    ? "w-full !text-white-700 rounded"
                    : ""
                )}
              >
               Blog Home
              </Link>
              <Link
                to={"/news"}
                className={classNames(
                  "p-2 px-3 text-white-100 text-sm  m-2 w-52",
                  window.location.pathname === "/dashboard"
                    ? "w-full !text-white-700 rounded"
                    : ""
                )}
              >
               News
              </Link>
             {/* <Link
                onClick={handleClick3}  
                to={"/business-data"}
                className={classNames(
                  "p-2 px-2 flex text-gray-100 text-sm  justify-between m-2 w-52",
                  window.location.pathname === "/business-data"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                <span className="">Business Data</span>
                {open3 ? <ExpandMore /> : <NavigateNextIcon />}
              </Link>

              <Collapse
                in={open3}
                className="flex flex-col"
                timeout="auto"
                unmountOnExit
              >
                <List className="!flex !flex-col !py-0">



                <Link
                    className={classNames(
                      "p-2 px-3 m-2 text-gray-100 text-sm w-52 pl-5",
                      window.location.pathname ===
                        "/business-category"
                        ? "w-full !text-gray-700 bg-white rounded"
                        : ""
                    )}
                    to={"/business-category"}
                  >
                    Business Type
                  </Link>


                  <Link
                    className={classNames(
                      "p-2 px-3 m-2 text-gray-100 text-sm w-52 pl-5",
                      window.location.pathname ===
                        "/business-category/business-sub-category"
                        ? "w-full !text-gray-700 bg-white rounded"
                        : ""
                    )}
                    to={"/business-category/business-sub-category"}
                  >
                    Business Sub Type
                  </Link>
                </List>
              </Collapse>

              <Link
                to={"/store"}
                className={classNames(
                  "p-2 px-3 text-gray-100 text-sm  m-2 w-52",
                  window.location.pathname === "/store"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                Store
              </Link>

              <Link
                to={"/store-wallet-name"}
                className={classNames(
                  "p-2 px-3 flex text-gray-100 text-sm  justify-between m-2 w-52",
                  window.location.pathname === "/store-wallet-name"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                <span className="text-sm">Manage Store Wallet</span>
              </Link>

              <Link
                onClick={handleClick1}
                to={"/manage-store"}
                className={classNames(
                  "p-2 px-3 flex text-gray-100 text-sm justify-between m-2 w-52",
                  window.location.pathname === "/manage-store"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                 Manage Store Data
                {open1 ? <ExpandMore /> : <NavigateNextIcon />}
              </Link>
              <Collapse
                in={open1}
                className="flex flex-col"
                timeout="auto"
                unmountOnExit
              >
                <Link
                  // onClick={handleClick1}
                  to={"/manage-product"}
                  className={classNames(
                    "p-2 flex text-gray-100 text-sm justify-between my-2 mx-5 w-52",
                    window.location.pathname === "/manage-product"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  Product Category
                </Link>
                <Link
                  to={"/product-brand"}
                  className={classNames(
                    "p-2 flex text-gray-100 text-sm justify-between my-2 mx-5 w-52",
                    window.location.pathname === "/product-brand"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  Product Brand
                </Link>
                <Link
                  to={"/product-sub-category"}
                  className={classNames(
                    "p-2 flex text-gray-100 text-sm justify-between my-2 mx-5 w-52",
                    window.location.pathname === "/product-sub-category"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  Product Sub Category
                </Link>
                <Link
                  style={{ paddingLeft: "24px" }}
                  onClick={() => setOpen5(!open5)}
                  to={"/variant-mangement"}
                  className={classNames(
                    "p-2 px-3 flex float-left text-gray-100 text-sm justify-between m-2 w-60",
                    window.location.pathname === "/variant-mangement"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  
                    Variant Management
                    {open5 ? <ExpandMore /> : <NavigateNextIcon />}
                  
                </Link>
                <Collapse in={open5} timeout="auto" unmountOnExit>
                  <List className="!flex !flex-col !py-0">
                    <Link
                      to={"/variant-mangement-color"}
                      className={classNames(
                        "p-2 px-5 text-gray-100 text-sm relative left-[23px] m-2 w-52 pl-8",
                        window.location.pathname === "/variant-mangement-color"
                          ? "w-full !text-gray-700 bg-white rounded"
                          : ""
                      )}
                    >
                      Manage Color Variant (Type One)
                    </Link>

                    <Link
                      to={"/variant-mangement-storage"}
                      className={classNames(
                        "p-2 px-5 text-gray-100 text-sm relative left-[23px] m-2 w-52 pl-8",
                        window.location.pathname ===
                          "/variant-mangement-storage"
                          ? "w-full !text-gray-700 bg-white rounded"
                          : ""
                      )}
                    >
                      Manage Storage Variant (Type Two)
                    </Link>

                    <Link
                      to={"/variant-management-other"}
                      className={classNames(
                        "p-2 px-5 m-2 text-gray-100 text-sm relative left-[23px] w-52 pl-8",
                        window.location.pathname === "/variant-management-other"
                          ? "w-full !text-gray-700 bg-white rounded"
                          : ""
                      )}
                    >
                      Manage Other Variant (Type Three)
                    </Link>
                  </List>
                </Collapse>

                <br />
                <Link
                  to={"/variant-combinations"}
                  style={{marginBottom:"-14px"}}
                  className={classNames(
                    "p-2 !px-3 flex mx-5 my-2 text-gray-100 text-sm justify-between !py-2 w-52",
                    window.location.pathname === "/variant-combinations"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  Variant Combinations
                </Link>
                <br />
                <Link
                  to={"/offer-management"}
                  // style={{marginBottom:"1px", marginTop:"14px"}}
                  className={classNames(
                    "p-2 flex text-gray-100 text-sm justify-between my-2 mx-5 w-52",
                    window.location.pathname === "/offer-management"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  Offer Management
                </Link>
              </Collapse>
              <Link
                  onClick={handleClick4}
                  to={"/manage-demography"}
                  className={classNames(
                    "p-2 px-3 flex text-gray-100 text-sm text-sm relative top-1 justify-between m-2 w-52",
                    window.location.pathname === "/manage-demography"
                      ? "w-full !text-gray-700 bg-white rounded"
                      : ""
                  )}
                >
                  Manage Demography
                  {open4 ? <ExpandMore /> : <NavigateNextIcon />}
                </Link>

                <Collapse in={open4} timeout="auto" unmountOnExit>
                  <List className="!flex !flex-col !py-0">
                    <Link
                      to={"/manage-demography/country"}
                      className={classNames(
                        "p-2 px-5 text-gray-100 text-sm  m-2 w-52 pl-8",
                        window.location.pathname ===
                          "/manage-demography/country"
                          ? "w-full !text-gray-700 bg-white rounded"
                          : ""
                      )}
                    >
                      Country List
                    </Link>

                    <Link
                      to={"/manage-demography/state"}
                      className={classNames(
                        "p-2 px-5 text-gray-100 text-sm m-2 w-52 pl-8",
                        window.location.pathname === "/manage-demography/state"
                          ? "w-full !text-gray-700 bg-white rounded"
                          : ""
                      )}
                    >
                      State List
                    </Link>

                    <Link
                      to={"/manage-demography/city"}
                      className={classNames(
                        "p-2 px-5 m-2 text-gray-100 text-sm w-52 pl-8",
                        window.location.pathname === "/manage-demography/city"
                          ? "w-full !text-gray-700 bg-white rounded"
                          : ""
                      )}
                    >
                      City List
                    </Link>
                  </List>
                </Collapse>
              <Link
                to={"/resellers"}
                className={classNames(
                  "p-2 px-3 text-gray-100 text-sm top-3 m-2 w-52",
                  window.location.pathname === "/resellers"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                Resellers Management
              </Link>
              <Link
                to={"/groups"}
                className={classNames(
                  "p-2 px-3 text-gray-100 text-sm m-2 w-52",
                  window.location.pathname === "/groups"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                Groups Management
              </Link>

              {/* store management end*/}

              {/* <Link
                to="/order-management"
                className={classNames(
                  "p-2 text-gray-100 text-sm px-3 m-2 w-52 ",
                  window.location.pathname === "/order-management"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                Orders Management
              </Link> */} 
              {/* <Link
                to="/order-management"
                className={classNames(
                  "p-2 text-gray-100 text-sm px-3 m-2 w-52 ",
                  window.location.pathname === "/order-management"
                    ? "w-full !text-gray-700 bg-white rounded"
                    : ""
                )}
              >
                Orders Management
              </Link> */}

              {/* <ListItem disablePadding>
              <Link
                to={"/customer-data"}
                className={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/customer-data"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Customer Data List
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                to={"/candidate-data"}
                className={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/candidate-data"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Condidate Data List
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                to={"/product-data"}
                clas sName={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/product-data"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Product Data List
              </Link>
            </ListItem>
            <ListItem disablePadding>
              <Link
                to={"/product-variant-data"}
                className={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/product-variant-data"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Product Variant Data List
              </Link>
            </ListItem> */}
            </List>
          {/* ) : ( */}
            {/* <List className="flex flex-col">
              <Link
                to="/dashboard"
                className={classNames(
                  "p-2 px-3 m-2 w-52 ",
                  window.location.pathname === "/resellers/dashboard"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Dashboard
              </Link>
              <Link
                to="/resellers/store-management"
                className={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/resellers/store-management"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Store Management
              </Link>
              <Link
                to="/resellers/pricing-and-plans"
                className={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/resellers/pricing-and-plans"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Pricing And Plans
              </Link>
              <Link
                to="/resellers/orders-history"
                className={classNames(
                  "p-2 px-3 m-2 w-52",
                  window.location.pathname === "/resellers/orders-history"
                    ? "w-full bg-sky-900 rounded"
                    : ""
                )}
              >
                Orders History
              </Link>
            </List> */}
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
