import LocalAtmTwoToneIcon from "@mui/icons-material/LocalAtmTwoTone";
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Avatar, Divider, TableCell, TableRow } from "@mui/material";
import { Circle } from "@mui/icons-material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LastWeekDashboard = ({ data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labelsWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const labelsYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const labelsMonth = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  const dataGraph = {
    labels:
      data === "₹2000"
        ? labelsWeek
        : data === "₹3000"
        ? labelsMonth
        : labelsYear,
    datasets: [
      {
        label: "Sales Report",
        data:
          data === "₹2000"
            ? [1, 2, 4, 8, 10, 16, 15]
            : data === "₹3000"
            ? [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
              ]
            : [5, 6, 2, 4, 6, 7, 8, 4, 6, 7, 9, 12],
        backgroundColor: "rgba(255, 255, 255, 0.06)",
      },
    ],
  };
  return (
    <div className="flex flex-col pr-4 h-[75vh] overflow-y-auto">
      <div className="grid grid-cols-3 pb-5 gap-10">
        <div className="bg-dark hover:bg-darkhover p-5 rounded-md cursor-pointer">
          <div className="flex gap-4 items-center">
            <LocalAtmTwoToneIcon color="success" fontSize="large" />
            <p>Number Of Sale</p>
          </div>
          <p className="text-7xl fontbold text-center my-2">{data}</p>
        </div>
        <div className="bg-dark hover:bg-darkhover p-5 rounded-md cursor-pointer">
          <div className="flex gap-4 items-center">
            <LocalAtmTwoToneIcon color="success" fontSize="large" />
            <p>Total Revenue</p>
          </div>
          <p className="text-7xl fontbold text-center my-2">{data}</p>
        </div>
        <div className="bg-dark hover:bg-darkhover p-5 rounded-md cursor-pointer">
          <div className="flex gap-4 items-center">
            <LocalAtmTwoToneIcon color="success" fontSize="large" />
            <p>Total Profit</p>
          </div>
          <p className="text-7xl fontbold text-center my-2">{data}</p>
        </div>

        <Bar
          height="80px"
          options={options}
          data={dataGraph}
          className="border-2 p-2 rounded border-darkborder"
        />
      </div>
      <Divider />
      <div className="my-3">
        <div className="flex justify-between">
          <p className="text-xl font-bold">Latest Orders</p>
          <p className="cursor-pointer hover:text-white">View All</p>
        </div>
        <div className="flex flex-col gap-3 my-2">
          {[1, 2, 3].map((index) => {
            return (
              <TableRow
                key={index + 1}
                component="div"
                className="!bg-dark w-full rounded-md"
              >
                <TableCell component="span" className="min-w-[275px] !p-10">
                  ABC-175815
                </TableCell>
                <TableCell component="span" className="min-w-[275px] !p-10">
                  Stark
                </TableCell>
                <TableCell component="span" className="min-w-[275px] !p-10">
                  7515
                </TableCell>
                <TableCell component="span" className="min-w-[275px] !p-10">
                  02/02/2023
                </TableCell>
                <TableCell component="span" className="min-w-[275px] !p-10">
                  <Circle color="success" /> Pending
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </div>
      <div className="my-3">
        <div className="flex justify-between">
          <p className="text-xl font-bold">Latest Request</p>
          <p className="cursor-pointer hover:text-white">View All</p>
        </div>
        <div className="flex flex-col gap-3 my-2">
          {[1, 2, 3].map((index) => {
            return (
              <TableRow
                key={index}
                component="div"
                className="!bg-dark w-full rounded-md"
              >
                <TableCell component="span" className="min-w-[275px] !p-10">
                  <span className="flex items-center gap-3">
                    <Avatar
                      src="https://staticimg.titan.co.in/Tanishq/Catalog/511240FAGAA00_1.jpg?impolicy=pqmed&imwidth=640"
                      variant="rounded"
                    />
                    Yukta Hearts Gold Ring
                  </span>
                </TableCell>
                <TableCell component="span" className="min-w-[180px] !p-10">
                  ABC-175815
                </TableCell>
                <TableCell component="span" className="min-w-[180px] !p-10">
                  Stark
                </TableCell>
                <TableCell component="span" className="min-w-[180px] !p-10">
                  Ring
                </TableCell>
                <TableCell component="span" className="min-w-[180px] !p-10">
                  7515
                </TableCell>
                <TableCell component="span" className="min-w-[180px] !p-10">
                  02/02/2023
                </TableCell>
                <TableCell component="span" className="min-w-[180px] !p-10">
                  <Circle color="success" /> Pending
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LastWeekDashboard;
