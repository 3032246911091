import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
const Dashboard = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const deleteBlog = (id) => {
   
    axios
      .post(
        `http://192.168.1.188:8000/blog/store-data-delete/`,
        { blog_id: id },
        {
          headers: {
            Token: "6030357fc8126488bf023e910c0dbe35026608c4",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log("Blog deleted successfully");
        blogData();
        alert(res.data.msg);
      })
      .catch((err) => {
        console.log("Error deleting blog", err);
      });
  };
 
  const blogData = () => {
    localStorage.getItem("token") ? navigate("/dashboard") : navigate("/login");
    axios
      .get("https://m1blog.aaragroups.com/blog/store-based-blog-list-api/", {
        headers: {
          token: "6030357fc8126488bf023e910c0dbe35026608c4",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data?.blog_list);
        setData(res.data?.blog_list);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        alert(err.response.data.message);
      });
  };
  useEffect(() => {
    blogData();
  }, []);

  return (
    <>
      <div className="flex justify-between item-center w-[100%]">
        <NavLink to="/dashboard/detail">
          <div className="text-xl font-bold !text-gray-600 p-3">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>

      {data?.map((item) => {
        return (
          <div className=" flex justify-between border border-gray-300 drop-shadow-2xl mx-auto rounded w-[98%] my-3">
            <div className="flex flex-col justify-center items-center">
              <p className="text-2xl text-black px-4 py-4">{item.title}</p>
              <p className="px-4 py-2">{item.meta_description}</p>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
              <img src={item.images} alt="ab" className="h-40 w-40" />
            </div>
            <div className="w-full flex  justify-center items-center px-5">
             
                <div className=" px-5 mt-32">
                  <Button
                    onClick={() =>
                      navigate("/dashboard/update/", {state:{id:item.id}})
                    }
                    variant="outlined"
                  >
                    Update
                  </Button>
                </div>
              
              <div className=" px-5 mt-32">
                <Button onClick={() => deleteBlog(item.id)} variant="outlined">
                  Delete
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Dashboard;
