import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Search from "../../shared/Search";
import { Active, Inactive, Unmaped } from "../../shared/ActivaInactive";
import { CustomButton } from "../../shared/CustomButton";
import { CustomIconButton } from "../../shared/CustomIconButton";
import AddBussinessCategory from "./AddBusinessCategory";
import { toast } from "react-toastify";
import CustomToggleButton from "../../shared/CustomToggleButton";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const BusinessCategory = () => {
  const [data, setData] = useState();
  const [store, setStore] = useState();
  const [businessCategory] = useState([]);
  const StoreList = () => {
    axios
      .get(`https://b1.bhaaraterp.com/store-data-list/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setStore(response?.data.store_list_data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // toast.error(error.message);
      });
  };

  const handleChange = (event) => {
    event.target.checked === true
      ? businessCategory.push(event.target.value)
      : businessCategory.pop(event.target.value);
    console.log("Category Array", businessCategory);
  };

  const handleActivateDeactivate = (event) => {
    event.target.checked === true
      ? ActivateBusinessCategory()
      : DeactivateBusinessCategory();
  };

  const ActivateBusinessCategory = () => {
    const reqBodyForActivate = {
      business_category: businessCategory,
    };

    console.log("Activate", reqBodyForActivate);

    axios
      .post(
        `https://b1.bhaaraterp.com/activate-business-category-store/`,
        { ...reqBodyForActivate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        BuisenesCategoryList();
        reqBodyForActivate.business_category.length !== 0
          ? toast.success(
              "Business Category with ID " +
                reqBodyForActivate.business_category +
                " are Activated"
            )
          : toast.warning("Please Select A Business Category First");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        alert(error.message);
      });
  };

  const DeactivateBusinessCategory = () => {
    const reqBodyForDeactivate = {
      business_category: businessCategory,
    };

    console.log("Deactivate", reqBodyForDeactivate);

    axios
      .post(
        `https://b1.bhaaraterp.com/deactivate-business-category-store/`,
        { ...reqBodyForDeactivate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        BuisenesCategoryList();
        reqBodyForDeactivate.business_category.length !== 0
          ? toast.success(
              "Business Category with ID " +
                reqBodyForDeactivate.business_category +
                " are Deactivated"
            )
          : toast.warning("Please Select A Business Category First");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        alert(error.message);
      });
  };

  const BuisenesCategoryList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response?.data.business_category_list_data);
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  useEffect(() => {
    BuisenesCategoryList();
    StoreList();
  }, []);
  const initialValues = {
    store: "",
    business_category: businessCategory,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        store: values.store_id,
        business_category: businessCategory,
      };

      console.log("ReqBody", reqBody);
      axios
        .post(
          `https://b1.bhaaraterp.com/map-business-category-store/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          BuisenesCategoryList();
          reqBody.business_category.length !== 0
            ? toast.success(
                "Business Category with IDs " +
                  reqBody.business_category +
                  " are maped to " +
                  reqBody.store
              )
            : toast.warning("Please Select A Business Category First");
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          alert(error.message);
        });
      action.resetForm();
    },
  });
  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl fon text-gray-800 font-bold">
              Business Category List
            </p>
            <p className="text-gray-700">View/Search category list</p>
          </div>
          <AddBussinessCategory />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col !my-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center bg-gray-100 justify-between p-2 h-14">
            <div className="flex bg-secondary">
              <CustomIconButton>
                <FilterAltIcon className="text-black" />
              </CustomIconButton>
              <Search />
            </div>
            <CustomToggleButton
              id="activate-deactivate"
              name="activate-deactivate"
              onChange={handleActivateDeactivate}
            />
            <div className="flex">
              <select
                className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border bg-white border-zinc-700"
                name="store_id"
                id="store_id"
                value={formik.values["store_id"]}
                onChange={formik.handleChange}
              >
                <option value="">Please select store from here</option>
                {store &&
                  store.map((store) => {
                    return (
                      <option key={store.id} value={store.id}>
                        {store.store_name}
                      </option>
                    );
                  })}
              </select>
              {/* <CustomButton type="submit" className="!p-1.5">
                Assign Category
              </CustomButton> */}
            </div>
          </div>
          {/* <div className="overflow-y-auto h-[440px]">
          <div className="flex justify-between items-center border-zinc-600 bg-secondary border-b-2">
            <div className="flex w-full items-center p-4">
              <input
                type="checkbox"
                className="border-gray-300 rounded h-5 w-5 mr-5"
              />
              <p>Category Name</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Category Code</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Status</p>
            </div>
            <div className="flex justify-center w-2/3 p-4">
              <p>Action</p>
            </div>
          </div>
          {data &&
            data.length > 0 &&
            data.map((cat) => {
              return (
                <div
                  key={cat.id}
                  className="flex justify-between items-center border-zinc-600 border-b"
                >
                  <div className="flex items-center w-full p-4">
                    <input
                      type="checkbox"
                      id={cat.id}
                      name={cat.id}
                      value={cat.id}
                      onChange={handleChange}
                      className="border-gray-300 rounded h-5 w-5 mr-5"
                    />
                    <p>{cat.name}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{cat.id}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    {cat.map_status === null ? (
                      <Unmaped />
                    ) : cat.map_status === 1 ? (
                      <Active />
                    ) : (
                      <Inactive />
                    )}
                  </div>
                  <div className="flex justify-center w-2/3 px-3">
                    <CustomIconButton>
                      <BorderColorIcon />
                    </CustomIconButton>
                    <CustomIconButton>
                      <DeleteIcon />
                    </CustomIconButton>
                  </div>
                </div>
              );
            })}
          </div> */}
          <div className="">
            <TableContainer
              component={Paper}
              // sx={{  }}
              sx={{ width: "100%", maxHeight: 440 }}
              className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Category Name
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Category Code
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((cat) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            component="th"
                            scope="row"
                          >
                            {cat.name}
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            {cat.id}
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            <div className=" mx-auto items-center justify-center ">
                              {cat.map_status === null ? (
                                <Unmaped />
                              ) : cat.map_status === 1 ? (
                                <Active />
                              ) : (
                                <Inactive />
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            <div className=" mx-auto justify-center text-center items-center">
                              <CustomIconButton>
                                <BorderColorIcon className="!text-center text-green-500"/>
                              </CustomIconButton>
                              <CustomIconButton>
                                <DeleteIcon className="!text-center text-red-500" />
                              </CustomIconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </form>
    </>
  );
};

export default BusinessCategory;
