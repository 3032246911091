import classNames from "classnames";
import React from "react";

export const CustomButton = ({ onClick, type, className, children }) => {
  return (
    <>
      <button
        onClick={onClick}
        type={type}
        className={classNames(
          "bg-white text-black my-auto p-1 mx-2 rounded-md px-2",
          className
        )}
      >
        {children}
      </button>
    </>
  );
};
