import React, { useEffect, useState } from "react";
import axios from "../../../config/axios";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Search from "../../../shared/Search";
import { Active, Inactive, Unmaped } from "../../../shared/ActivaInactive";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import { CustomButton } from "../../../shared/CustomButton";
import AddState from "../AddState";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CustomToggleButton from "../../../shared/CustomToggleButton";

const StateList = () => {
  const [data, setData] = useState();
  const [store, setStore] = useState();
  const [state] = useState([]);
  const StoreList = () => {
    axios
      .get(`https://b1.bhaaraterp.com/store-data-list/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setStore(response?.data.store_list_data);
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

  const handleChange = (event) => {
    event.target.checked === true
      ? state.push(event.target.value)
      : state.pop(event.target.value);
    console.log("State Selcted", state);
  };

  const handleActivateDeactivate = (event) => {
    event.target.checked === true ? ActivateCountry() : DeactivateCountry();
  };
  const ActivateCountry = () => {
    const reqBodyForActivate = {
      state: state,
    };

    console.log("Activate", reqBodyForActivate);

    axios
      .post(
        `https://b1.bhaaraterp.com/activate-state-store/`,
        { ...reqBodyForActivate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        stateList();
        reqBodyForActivate.state.length !== 0
          ? toast.success(
              "State with ID " + reqBodyForActivate.state + " are Activated"
            )
          : toast.warning("Please Select A State First");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        alert(error.message);
      });
  };

  const DeactivateCountry = () => {
    const reqBodyForDeactivate = {
      state: state,
    };

    console.log("Deactivate", reqBodyForDeactivate);

    axios
      .post(
        `https://b1.bhaaraterp.com/deactivate-state-store/`,
        { ...reqBodyForDeactivate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        stateList();
        reqBodyForDeactivate.state.length !== 0
          ? toast.success(
              "State with ID " + reqBodyForDeactivate.state + " are Deactivated"
            )
          : toast.warning("Please Select A State First");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        alert(error.message);
      });
  };

  const reqBody = {};

  const stateList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/state-list-data/`,
        { ...reqBody },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response?.data.country_data_list);

        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

  useEffect(
    () => {
      StoreList();
      stateList();
    },
    // eslint-disable-next-line
    []
  );

  const initialValues = {
    store: "",
    state: "",
  };
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        store: values.store_id,
        state: state,
      };

      console.log("ReqBody", reqBody);
      axios
        .post(
          `https://b1.bhaaraterp.com/map-state-store/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          stateList();
          reqBody.state.length !== 0
            ? toast.success(
                "State with IDs " +
                  reqBody.state +
                  " are maped to " +
                  reqBody.store
              )
            : toast.warning("Please Select A State First");
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          alert(error.message);
        });
      action.resetForm();
    },
  });

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl text-gray-800 font font-bold">State List</p>
            <p className="text-gray-700">Manage your State List</p>
          </div>
          <AddState />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col !my-3 !rounded  bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
            <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
              <div className="flex">
                <CustomIconButton>
                  <FilterAltIcon />
                </CustomIconButton>
                <Search />
              </div>
              <CustomToggleButton
                id="activate-deactivate"
                name="activate-deactivate"
                onChange={handleActivateDeactivate}
              />
              <div className="flex">
                <select
                  className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                  name="store_id"
                  id="store_id"
                  value={formik.values["store_id"]}
                  onChange={formik.handleChange}
                >
                  <option value="">Please select store from here</option>
                  {store &&
                    store.map((store) => {
                      return (
                        <option key={store.id} value={store.id}>
                          {store.store_name}
                        </option>
                      );
                    })}
                </select>
                <CustomButton className="!p-1.5">
                  Assign State List
                </CustomButton>
              </div>
            </div>
            <div className="flex justify-between items-center border-zinc-600 bg-secondary border-b-2">
              <div className="flex w-full items-center p-4">
                <input
                  type="checkbox"
                  className="border-gray-300 rounded h-5 w-5 mr-5"
                />
                <p>State Name</p>
              </div>
              <div className="flex justify-center w-full p-4">
                <p>Country Name</p>
              </div>
              <div className="flex justify-center w-full p-4">
                <p>Status</p>
              </div>
              <div className="flex justify-center w-full">
                <p>Action</p>
              </div>
            </div>
            {data &&
              data.map((state) => {
                return (
                  <div
                    key={state.id}
                    className="flex justify-between items-center border-zinc-600 border-b"
                  >
                    <div className="flex w-full items-center p-4">
                      <input
                        id={state.id}
                        name={state.id}
                        value={state.id}
                        onChange={handleChange}
                        type="checkbox"
                        className="border-gray-300 rounded h-5 w-5 mr-5"
                      />
                      <p>{state.state}</p>
                    </div>
                    <div className="flex justify-center w-full p-4">
                      <p>{state.country_name}</p>
                    </div>
                    <div className="flex justify-center w-full p-4">
                      {state.map_status === null ? (
                        <Unmaped />
                      ) : state.map_status === 1 ? (
                        <Active />
                      ) : (
                        <Inactive />
                      )}
                    </div>
                    <div className="flex justify-center w-full">
                      <CustomIconButton>
                        <BorderColorIcon />
                      </CustomIconButton>
                      <CustomIconButton>
                        <DeleteIcon />
                      </CustomIconButton>
                    </div>
                  </div>
                );
              })}
          </div>
        </form>
      </div>
    </>
  );
};

export default StateList;
