
export const permissionData = [
  { name: "sales_update", id: "sales_update", item: "Sales Update" },
  { name: "erp_update", id: "erp_update", item: "Erp Update" },
  { name: "hrm_update", id: "hrm_update", item: "Hrm Update" },
  { name: "api_update", id: "api_update", item: "API Update" },
  { name: "purchase_update", id: "purchase_update", item: "Purchase Update" },
  { name: "leads_update", id: "leads_update", item: "Leads Update" },
  { name: "ledger_update", id: "ledger_update", item: "Ledger Update" },
  { name: "report_update", id: "report_update", item: "Report Update" },
];
