import Store from "../components/Store";
import BusinessCategory from "../components/BusinessCategory";
import StorePermission from "../components/Store/StorePermissions";
import BusinessSubCategory from "../components/BusinessCategory/BusinessSubCategory";
import Testing from "../components/Testing";
// import ManageDemoPlaces from "../components/ManageDemoPlaces";
import VariantManagemnet from "../components/VariantMangement";
import VariantCombinations from "../components/VariantCombinations";
import Dashboard from "../components/Dashboard";

import OfferManagement from "../components/OfferManagement";
import CountryList from "../components/ManageDemoPlaces/CountryList";
import StateList from "../components/ManageDemoPlaces/StateList";
import CityList from "../components/ManageDemoPlaces/CityList";
import CustomerData from "../components/CustomerData";
import CondidateData from "../components/CandidateData";
import ProductData from "../components/ProductData";
import ProductVariantData from "../components/ProductVariantData";
import Resellers from "../components/Resellers/ListResellers";
import ResellersDashboard from "../components/Reseller/ResellersDashboard";

import ResellerProfile from "../components/Reseller/ResellerProfile";
import ResellerUpdateProfile from "../components/Reseller/ResellerUpdateProfile";
import StoreList from "../components/Reseller/StoreManagement/StoreList";
import OrdersHistory from "../components/Reseller/OrdersHistory";
import ChoosePlans from "../components/Reseller/PricingAndPlans/ChoosePlans";
import PlanDetails from "../components/Reseller/PricingAndPlans/PlanDetails";
import Groups from "../components/Groups";
import AddGroup from "../components/Groups/AddGroups";
import GroupDetail from "../components/Groups/GroupDetails";
import OrderManagement from "../components/OrderManagement";
import ProductCategory from "../components/ProductCategory";
import ProductBrand from "../components/ProductCategory/ProductBrand";
import ProductSubCategory from "../components/ProductCategory/ProductSubCategory";
import WalletManagement from "../components/WalletManagement";
import ProductVariantList from "../components/VariantMangement/ProductVariantList";
import VariantStorage from "../components/VariantStorage";
import VariantOther from "../components/VariantOther";


import ProductStorage from '../components/VariantStorage/ProductStorage'
import ProductOther from '../components/VariantOther/ProductOther'
import ProductChoice from "../components/VariantOther/ProductOther/ProductChoice";

import OrderDetail from "../components/Dashboard/OrderDetail"
import Update from "../components/Update";
import News from "../components/News";

export const routes = [
  {
    id: 1,
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    id: 2,
    path: "/store",
    component: <Store />,
  },
  {
    id: 3,
    path: "/store/:id",
    component: <StorePermission />,
  },
  {
    id: 4,
    path: "/business-data",
    component: <BusinessCategory />,
  },
  {
    id: 4,
    path: "/business-category",
    component: <BusinessCategory />,
  },
  {
    id: 5,
    path: "/business-category/business-sub-category",
    component: <BusinessSubCategory />,
  },
  {
    id: 6,
    path: "/test",
    component: <Testing />,
  },



  {
    id: 7,
    path: "/manage-store",
    component: <ProductCategory />,
  },

  


  {
    id: 7,
    path: "/manage-demography",
    component: <CountryList />,
  },
  {
    id: 7,
    path: "/manage-demography/country",
    component: <CountryList />,
  },
  {
    id: 7,
    path: "/manage-demography/state",
    component: <StateList />,
  },
  {
    id: 7,
    path: "/manage-demography/city",
    component: <CityList />,
  },


  {
    id: 8,
    path: "/variant-mangement",
    component: <VariantManagemnet />,
  },
  {
    id: 8,
    path: "/variant-mangement-color",
    component: <VariantManagemnet />,
  },
  {
    id: 8,
    path: "/variant-mangement-storage",
    component: <VariantStorage />,
  },
  {
    id: 8,
    path: "/variant-management-other",
    component: <VariantOther />,
  },
  {
    id: 9,
    path: "/variant-combinations",
    component: <VariantCombinations />,
  },
  {
    id: 10,
    path: "/offer-management",
    component: <OfferManagement />,
  },
  {
    id: 11,
    path: "/customer-data",
    component: <CustomerData />,
  },
  {
    id: 12,
    path: "/candidate-data",
    component: <CondidateData />,
  },
  {
    id: 13,
    path: "/product-data",
    component: <ProductData />,
  },
  {
    id: 14,
    path: "/product-variant-data",
    component: <ProductVariantData />,
  },
  {
    id: 15,
    path: "/resellers",
    component: <Resellers />,
  },
  {
    id: 16,
    path: "/groups",
    component: <Groups />,
  },
  {
    id: 17,
    path: "/add-group",
    component: <AddGroup />,
  },
  {
    id: 18,
    path: "/group-detail/:id",
    component: <GroupDetail />,
  },
  {
    id: 19,
    path: "/order-management",
    component: <OrderManagement />,
  },
  {
    id: 21,
    path: "/manage-product",
    component: <ProductCategory />,
  },
  {
    id: 22,
    path: "/product-brand",
    component: <ProductBrand/>,
  },
  {
    id: 23,
    path: "/product-sub-category",
    component: <ProductSubCategory/>,
  },
  {
    id: 24,
    path: "/store-wallet-name",
    component: <WalletManagement/>,
  },

  {
    id: 24,
    path: "/variant-mangement-color/variant-list",
    component: <ProductVariantList/>,
  },
  {
    id: 24,
    path: "/variant-mangement-storage/variant-storage-list",
    component: <ProductStorage/>,
  },
  {
    id: 24,
    path: "/variant-management-other/variant-other-list",
    component: <ProductOther/>,
  },
  {
    id: 25,
    path: "/variant-management-other/variant-other-list/choice",
    component: <ProductChoice/>,
  },
  {
    id: 26,
    path: "/dashboard/detail",
    component: <OrderDetail/>,
  },
  {
    id: 27,
    path: "/dashboard/update/",
    component: <Update />,
  },
  {
    id: 28,
    path: "/news",
    component: <News/>,
  },


];
// OrderDetail

// 
export const resellersRoutes = [
  {
    id: 1,
    path: "/resellers/dashboard",
    component: <ResellersDashboard />,
  },
  {
    id: 2,
    path: "/resellers/store-management",
    component: <StoreList />,
  },
  {
    id: 3,
    path: "/resellers/profile",
    component: <ResellerProfile />,
  },
  {
    id: 4,
    path: "/resellers/update-profile",
    component: <ResellerUpdateProfile />,
  },
  {
    id: 5,
    path: "/resellers/pricing-and-plans",
    component: <ChoosePlans />,
  },
  {
    id: 6,
    path: "/resellers/orders-history",
    component: <OrdersHistory />,
  },
  {
    id: 7,
    path: "/resellers/pricing-and-plans/details",
    component: <PlanDetails />,
  },
];


