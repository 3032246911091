import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams, Navigate, useNavigate } from "react-router-dom";

const OrderDetail = () => {
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState("");
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const navegate=useNavigate()
  const handleEditorChange = (e) => {
    setContent(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const addBlog = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("store", localStorage.getItem("store_id"));
    reqbody.append("title", title);
    reqbody.append("slug", slug);
    reqbody.append("meta_description", description);
    reqbody.append("Content", content);
    reqbody.append("images", image);
    reqbody.append("category", category);
    reqbody.append("author", localStorage.getItem("user_id"));

    axios
      .post("https://m1blog.aaragroups.com/blog/create-blog-data/", reqbody, {
        headers: {
          "Content-Type": "multipart/form-data",
          Token: "6030357fc8126488bf023e910c0dbe35026608c4",
        },
      })
      .then((response) => {
        console.log("Blog added successfully:", response.data);
        response.data.message === "your blog succesfully created" && navegate("/dashboard")
      })
      .catch((error) => {
        console.error("Error adding blog:", error);
      });
  };
  const blogData = () => {
    axios
      .get('https://m1blog.aaragroups.com/blog/blog-category-list-data/', {
        headers: {
          token: '6030357fc8126488bf023e910c0dbe35026608c4',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        console.log('RESPONSE ==== : ', res?.data?.data)
        setData(res?.data?.data)
      })
      .catch((err) => {
        console.log('ERROR: ====', err)
        alert(err.response?.data.message)
      })
  }
  useEffect(() => {
    blogData()
  }, [])
  

  return (
    <div className="lg:flex-row flex flex-col justify-center gap-5">
      <div className="lg:w-3/5 w-full flex flex-col justify-center">
        <div className="bg-white shadow-lg mt-5">
        <form onSubmit={addBlog}>
        <div className=" border-gray-300 rounded-md p-2">
            <div className="flex-col gap-5">
              <div className=" w-full pl-3">
                <div>
                  <p className="text-sm font-semibold p-1">Title</p>
                  <input
                    placeholder=""
                    className="w-full p-2 px-4 border border-gray-300 rounded m-1"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className=" w-full pl-3">
                <div>
                  <p className="text-sm font-semibold p-1">Slug:</p>
                  <input
                    placeholder=""
                    className="w-full p-2 px-4 border border-gray-300 rounded m-1"
                    onChange={(e) => setSlug(e.target.value)}
                  />
                </div>
              </div>
              <div className=" w-full pl-3">
                <div>
                  <p className="text-sm font-semibold p-1">Description:</p>
                  <input
                    placeholder=""
                    className="w-full p-2 px-4 border border-gray-300 rounded m-1"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className=" w-full pl-3">
                <div>
                  <p className="text-sm font-semibold p-1">Author:</p>
                  <input
                    placeholder="Title *"
                    className="w-full p-2 px-4 border border-gray-300 rounded m-1"
                    // onChange={(e) => setHousNo(e.target.value)}
                  />
                </div>
              </div> */}
              <div>
                <p className="text-sm font-semibold p-1">Categrory</p>
                <select
                  className="w-full p-2 px-4 border border-gray-300 rounded m-1"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option>Select categrory</option>
                  {data?.map((cat) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className=" w-full pl-3">
                <div>
                  <p className="text-sm font-semibold p-1">File:</p>
                  <input type="file" onChange={handleImageChange}></input>
                </div>
              </div>
              <div className=" w-full pl-3">
                <div>
                  <p className="text-sm font-semibold p-1">Content:</p>
                  <textarea
                    className="w-full bg-gray-100 text-lg font-semibold border border-gray-700"
                    value={content}
                    onChange={handleEditorChange}
                  ></textarea>
                </div>
              </div>
              <div className=" w-full pl-3">
                <Button type="submit" variant="contained">Add</Button>
              </div>
            </div>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
