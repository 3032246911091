// import React from 'react'

// const ProductChoice = () => {
//   return (
//     <div>
//       this is choice page
//     </div>
//   )
// }

// export default ProductChoice

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Active, Inactive, Unmaped } from "../../../../shared/ActivaInactive";
import { CustomIconButton } from "../../../../shared/CustomIconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomToggleButton from "../../../../shared/CustomToggleButton";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getCalendarPickerSkeletonUtilityClass } from "@mui/lab";
import { InputLabel, MenuItem, Select } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductChoice = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [page,setPage]=useState(1)

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [data, setData] = useState([]);

  // add product variant list start
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [name1, setName1] = useState("");
  // add product variant list end

  // edit product variant list start
  const [rowData, setRowData] = useState([]);
  const [ids, setIds] = useState(null);
  const [addBtn, setAddBtn] = useState(true);
  // edit product variant list end
  const [searchQuery, setSearchQuery] = useState("");

  const previousData = () => {
    setPage(page - 1);
  };
  const nextData = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    axios
      .get(
        `https://b1.bhaaraterp.com/choice-other-variant-data/?page=${page}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response?.data?.data);
        console.log("Product variant List===>", response?.data?.data);
      })
      .catch((error) => {
        // console.log("page not found", error);
        alert(error?.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://b1.bhaaraterp.com/product-category-list-data/?page=2&business_sub_category_id&product_category_name`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log("product-category-list-data===>", response?.data?.data);
        setCategory(response?.data?.data);
      })
      .catch((error) => {
        console.log("page not found", error);
      });
  }, []);

  useEffect(() => {
    rowData !== [] && setName1(rowData?.choice);
    setCategoryId(rowData?.category?.id);
  }, [rowData]);

  const createProduct = () => {
    const subData = {
      id: ids,
      choice: name1,
    };
    !ids
      ? axios
          .post(
            `https://b1.bhaaraterp.com/choice-other-variant-data/`,
            subData,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            console.log("success");
            console.log(response?.data?.message);
            setCategoryId("");
            setName1("");
            toast(response?.data?.message);
          })
          .catch((error) => {
            console.log("error page", error?.response);
            console.log(error);
          })
      : axios
          .post(
            `https://b1.bhaaraterp.com/choice-other-variant-data/`,
            subData,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            console.log("success");
            console.log(response?.data?.message);
            setCategoryId("");
            setName1("");
            toast(response?.data?.message);
            setAddBtn(true);
          })
          .catch((error) => {
            console.log("error page", error?.response);
            console.log(error);
          });
  };

  const EditData = (row) => {
    setRowData(row);
    setIds(row.id);
    setAddBtn(false);
  };

  console.log(ids);

  return (
    <div className="bg-slate-100">
      <div className="flex justify-between bg-slate-100">
        <div className="p-2">
          <p className="text-xl font font-bold text-black">
            Your Choice ( Type Three ){" "}
          </p>
          <p className="text-black">Manage/Product Variants Storage</p>
        </div>
        <div className="mt-6 mr-3">
          <Button
            onClick={handleOpen}
            className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
            style={{border:"1px solid black"}}
          >


            + Add New Choice
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {addBtn ? (
                  <div className="!text-center underline">
                    Add Variant Choice
                  </div>
                ) : (
                  <div className="!text-center underline">
                    Update Variant Choice
                  </div>
                )}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="gap-y-3">
                  {/* <TextField id="outlined-basic" label="Outlined"  variant="outlined" /> */}
                  {/* <InputLabel id="demo-simple-select-label">Category Name</InputLabel>
                                    <Select
                                        className="!w-80 !mt-2 !text-black"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categoryId}
                                        onChange={(e) => setCategoryId(e.target.value)}
                                    >
                                     {category ?
                                            category.map((elem) =>
                                                <MenuItem value={elem.id}>{elem?.title}</MenuItem>
                                            )
                                            : null
                                        }
                                    </Select> */}
                  <TextField
                    value={name1}
                    onChange={(event) => setName1(event.target.value)}
                    id="outlined-basic"
                    label="value"
                    variant="outlined"
                    className="!w-80 !mt-2"
                  />
                </div>
                <div className="justify-between items-center ">
                  <Button
                    variant="outlined"
                    className="float-left top-3"
                    onClick={handleClose}
                  >
                    CANCEL
                  </Button>
                  {addBtn ? (
                    <Button
                      variant="outlined"
                      className="float-right top-3"
                      style={{ marginRight: "15px" }}
                      onClick={createProduct}
                    >
                      CREATE
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      className="float-right top-3"
                      style={{ marginRight: "15px" }}
                      onClick={createProduct}
                    >
                      UPDATE
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>

      <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
          <div className="flex">
            <CustomIconButton>
              <FilterAltIcon className="!text-black" />
            </CustomIconButton>
            <input
              type="text"
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>
          {/* <CustomToggleButton
            id="activate-deactivate"
            name="activate-deactivate"
          /> */}
          {/* <div className="flex">
                        <select
                            className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                            name="store_id"
                            id="store_id"
                        >
                            <option>hello</option>
                            <option>hello</option>
                            <option>hello</option>
                        </select>
                    </div> */}
        </div>

        <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                    >
                  {/* <input
                    type="checkbox"
                    className="border-gray-300 rounded h-5 w-5 mr-2"
                  /> */}
                  ID
                </TableCell>
                <TableCell
                  style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                >
                  YOUR CHOICE
                </TableCell>
                <TableCell
                  style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data
                  ?.filter(
                    (item) =>
                      item.choice &&
                      item.choice
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )

                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                          >
                        {/* <input
                          id={row?.id}
                          name={row?.id}
                          type="checkbox"
                          className="border-gray-300 rounded h-5 w-5 mr-5"
                        /> */}
                        {row?.id}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}>{row?.choice}</TableCell>

                      <TableCell className="!text-center !text-[12px]"
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                          }}>
                        <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                        <button onClick={handleOpen}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditData(row)}
                          />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="!float-right bg-white w-[100%] relative mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-300 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="float-right">
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductChoice;
