import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://b1.bhaaraterp.com/",
  });

axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      config.headers = {
        Authorization: token,
        ...config.headers,
      };
      return config;
    },
    (err) => Promise.reject(err)
  );
  

export default axiosInstance;
