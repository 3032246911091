import React, { useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { Box, Modal } from "@mui/material";
import { CustomButton } from "../../../shared/CustomButton";

const AddCity = () => {
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const CountryList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/country-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCountry(response?.data.country_data_list);
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const StateList = () => {
    const reqBody = {
      country_id: value.length !== 0 || value,
    };
    axios
      .post(
        `https://b1.bhaaraterp.com/state-list-data/`,
        { ...reqBody },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setState(response?.data.country_data_list);
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

  // console.log(value);
  console.log("value", value);
  useEffect(
    () => {
      value.length === 0 || StateList();
    },

    // eslint-disable-next-line
    [value]
  );

  useEffect(() => {
    CountryList();
  }, []);

  console.log("State", state);
  const initialValues = {
    state_id: "",
    city_name: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        state_id: values.state_id,
        city_name: values.city_name,
      };

      console.log("ReqBody", reqBody);

      axios
        .post(
          `https://b1.bhaaraterp.com/add-city-data/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          alert(error.message);
        });
      action.resetForm();
    },
  });

  return (
    <>
      <CustomButton onClick={handleOpen}>+ Add City</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 text-zinc-300 bg-secondary outline-none -translate-x-1/2 -translate-y-1/2 w-1/3 shadow-md p-5 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col mx-auto gap-2"
          >
            <p className="text-2xl underline font-semibold text-white text-center my-5">
              Add New City
            </p>

            <p>Country</p>
            <select
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700 text-black"
              name="country_id"
              id="country_id"
              onChange={handleChange}
            >
              <option value="">Select Country</option>
              {country &&
                country.length > 0 &&
                country.map((country) => {
                  return (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
            </select>
            <p className="text-left text-white">State Name</p>
            <select
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700 text-black"
              name="state_id"
              id="state_id"
              value={formik.values["state_id"]}
              onChange={formik.handleChange}
            >
              <option value="">Select State</option>;
              {state &&
                state.map((state) => {
                  return (
                    <option key={state.id} value={state.id}>
                      {state.state}
                    </option>
                  );
                })}
            </select>
            <p className="text-left text-white">City Name</p>
            <input
              autoComplete="off"
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700 text-black"
              name="city_name"
              id="city_name"
              value={formik.values["city_name"]}
              onChange={formik.handleChange}
              placeholder="Enter City Name"
            />
            <CustomButton className="!mx-auto !px-6 !my-7 !p-2" type="submit">
              Add New City
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddCity;
