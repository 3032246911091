import React, { useEffect, useState } from "react";
import axios from "../../config/axios";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Search from "../../shared/Search";
import { Active, Inactive } from "../../shared/ActivaInactive";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomIconButton } from "../../shared/CustomIconButton";
import { CustomButton } from "../../shared/CustomButton";
import AddOfferType from "./AddOfferType";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Switch from "react-switch";    
import { Filter } from "@mui/icons-material";

const OfferManagement = () => {
  const [data, setData] = useState([]);
  const [store,setStore]=useState([])
  const [id,setId]=useState(null)
  const [filData,setFilData]=useState([])
  const [switBtn,setSwitchBtn]=useState()


  const offerData=()=>{
    id ==null ?
      axios
        .get(
          `https://b1.bhaaraterp.com/offer-list-for-admin/?page=1`,
          {},
          {
            headers: {
              Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
            },
          }
        )
        .then((response) => {
          setData(response?.data?.data);
          // console.log("offer data==>",response?.data?.data)
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        })
        : 
        axios
        .get(
          `https://b1.bhaaraterp.com/offer-filler-by-store/?store_id=${id}&page=1`,
          {},
          {
            headers: {
              Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
            },
          }
        )
        .then((response) => {
          setData(response?.data?.data);
          // console.log("filter data==>",response?.data?.data)
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        })

  }

  useEffect( () => { 
    offerData()
    },[id])

  useEffect(()=>{
    axios
        .get(
          `https://b1.bhaaraterp.com/offer-filler-by-store/?store_id=${id}&page=1`,
          {},
          {
            headers: {
              Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
            },
          }
        )
        .then((response) => {
          setFilData(response?.data?.data);
          // console.log("filter data==>",response?.data?.data)
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        });
  },[id])

  const storeData=()=>{
    axios
        .get(
          `https://b1.bhaaraterp.com/store-data-list/`,
          {},
          {
            headers: {
              Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
            },
          }
        )
        .then((response) => {
          setStore(response?.data?.store_list_data);
          // console.log("store data==>",response)
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        });
  }

  useEffect(()=>{
    storeData()
  },[])


  const handleChange=(offerID)=>{
    // console.log("offer id=====>",offerID)

    axios
          .post(
            `https://b1.bhaaraterp.com/offer-enable-disable-for-admin/`,
            { offer_id: offerID},
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            console.log("succes");
            offerData()
          })
          .catch((error) => {
            console.log("", error.response);
            console.log(error);
            // alert(error.message);
          });
  }



  return (
    <>
      <div className="flex flex-col">
        <div className="flex bg-gray-100 justify-between">
          <div className="p-2">
            <p className="text-xl text-black font-bold">Manage offers</p>
            <p className="text-gray-800">Manage your Offers</p>
          </div>
          {/* <AddOfferType /> */}
        </div>

        <div className="flex flex-col !my-3 !rounded border-dark shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center bg-gray-100 justify-between p-2 h-14 border-zinc-600">
            <div className="flex">
              <CustomIconButton>
                <FilterAltIcon className="text-black" />
              </CustomIconButton>
              <Search />
            </div>
            <div className="flex">
              <select
                className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                name="store_id"
                id="store_id"
                onChange={(event)=>setId(event.target.value)}
              >
                <option value="">Please select store from here</option>
                {
                  store?.map((elem)=>
                  <option value={elem?.id}>{elem?.store_name}</option>
                  )
                }
                
              </select>
              {/* <CustomButton className="!p-1.5">Assign Offers</CustomButton> */}
            </div>
          </div>
          {/* <div className="flex justify-between items-center border-zinc-600 bg-secondary border-b-2">
            <div className="flex w-full items-center p-4">
              <input
                type="checkbox"
                className="border-gray-300 rounded h-5 w-5 mr-5"
              />
              <p>Title</p>
            </div>
            <div className="flex w-full p-4">
              <p>Offer Type</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Value</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Status</p>
            </div>
            <div className="flex justify-center w-full">
              <p>Action</p>
            </div>
          </div> */}
          {/* {data &&
            data.map((offer) => {
              return (
                <div
                  key={offer.id}
                  className="flex justify-between items-center border-zinc-600 border-b"
                >
                  <div className="flex w-full items-center p-4">
                    <input
                      type="checkbox"
                      className="border-gray-300 rounded h-5 w-5 mr-5"
                    />
                    <p>{offer.title}</p>
                  </div>
                  <div className="flex w-full p-4">
                    <p>{offer.discount_type}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{offer.value}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{offer.status === 1 ? <Active /> : <Inactive />}</p>
                  </div>
                  <div className="flex justify-center w-full">
                    <CustomIconButton
                    // onClick={() => {
                    //   navigate(`/store/${store.id}`);
                    // }}
                    >
                      <BorderColorIcon />
                    </CustomIconButton>
                    <CustomIconButton
                    // onClick={() => {
                    //   navigate(`/store/${store.id}`);
                    // }}
                    >
                      <DeleteIcon />
                    </CustomIconButton>
                  </div>
                </div>
              );
            })} */}
          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    {/* <input
                    type="checkbox"
                    className="border-gray-300 rounded h-5 w-5 mr-2"
                  /> */}
                    <div className="w-16">SR NO.</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">CREATE DATE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VALID FROM</div>
                    
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VALID UNTIL</div>
                    
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">ACTIVE STATUS</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-24">TYPE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">DISCOUNT UNIT</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">DISCOUNT VALUE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-28">OFFER ON</div>
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    EDIT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data ?
                  data?.map((row,ind) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                  
                  {ind+1}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.create_date.slice(0,10)}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {row?.valid_from.slice(0,10)}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                      {row?.valid_until.slice(0,10)}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >

                        <Switch
                              checked={row?.active_status || false}
                              onChange={() => handleChange(row?.id)}
                              // inputProps={{ 'aria-label': 'controlled' }}
                              label="Jason Killian"
                              size="small"
                        />
                        
                        {/* {row.status === 1 ? <div className="mx-auto"><Active/></div> : <div className="mx-auto"><Inactive/></div>} */}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                      {row?.offer_type}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                     {row?.discount_unit}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                      {/* discount_value */}
                      {row?.discount_value}
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                      offer on
                      </TableCell>
                      <TableCell
                        className="!text-center !text-[12px]"
                        style={{
                          borderRight: "1px solid #D0D0D0",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        <CustomIconButton
                        // onClick={() => {
                        //   navigate(`/store/${store.id}`);
                        // }}
                        >
                          <BorderColorIcon className="text-green-600 !text-[14px]"/>
                        </CustomIconButton>
                        {/* <CustomIconButton>
                          <DeleteIcon className="text-red-600" />
                        </CustomIconButton> */}
                      </TableCell>

                      {/* {offer.status === 1 ? <Active /> : <Inactive />} */}
                      {/* <TableCell className="!text-center !text-[12px]"
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                          }}>
                        <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                        <button onClick={handleOpen}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditData(row)}
                          />
                        </button>
                      </TableCell> */}
                    </TableRow>
                  ))

                  :
                  <div className="text-[20px] text-red-500"> No Store Found</div>
                
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  )
  
};

export default OfferManagement;
