import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import axios from "axios";
import Search from "../../../shared/Search";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import AddIcon from "@mui/icons-material/Add";

import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
};

const ProductBrand = () => {
   
  const [brandId,setBrandId]=useState(null)
  const [businessSubCategoryId,setBussinessCategoryId]=useState("")
  const [businessFormValidId,setBusinessFormValidId]=useState("")
  const [brandName,setBrandName]=useState("")
  const [image,setImage]=useState("")
  const [description,setDescription]=useState("")
  const [metaTitle,setMetaTitle]=useState("")
  const [metaDecription,setMetaDescription]=useState("")
  const [metaKeyword,setMetaKeyword]=useState("")





  const [productData, setProductData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowData, setRowData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [toggleBtn, setToggleBtn] = useState(true);
  const [category, setCategory] = useState([]);


  const handleChange = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
  };

  const CategoryList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCategory(response.data.business_category_list_data);
        // console.localStorage(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

 
  const submitCategory = () => {
    console.log("imageData==>", image);
    const fd = new FormData();
    fd.append("brand_id", brandId);
    fd.append("business_sub_category_id", businessFormValidId);
    fd.append("product_brand_name", brandName);
    fd.append("description", description);
    fd.append("image", image);
    fd.append("meta_title", metaTitle);
    fd.append("meta_description", metaDecription);
    fd.append("meta_keyword", metaKeyword);

    rowData.length === 0
      ?
      axios
          .post(`https://b1.bhaaraterp.com/add-product-brand-data/`, fd, {
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "multipart/formdata",
            },
          })
          .then((response) => {
            // setStore(response?.data.store_list_data);
            toast.success(response.data.message);
            console.log("business store ==>", response.data.data);
          })
          .catch((error) => {
            console.log("error page", error.response);
            console.log(error);
          })
         : // Edit functionality to business category
        axios
          .post(`https://b1.bhaaraterp.com/edit-product-brand-data/`, fd, {
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "multipart/formdata",
            },
          })
          .then((response) => {
            // setStore(response?.data.store_list_data);
            toast.success(response.data.message);
            handleClose1()
            // console.log("business store ==>", response.data.data)
            
            
          })
          .catch((error) => {
            console.log("error page", error.response);
            console.log(error);
          });
  };

  // console.log("array data===>",rowData.length)
  useEffect(() => {
    


  }, [rowData]);


  const previousData = () => {
    setPage(page - 1);
  };
  const nextData = () => {
    setPage(page + 1);
  };

  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);




  const StoreList = () => {
    const reqbody = new FormData();
    reqbody.append(
      "business_category_ids",
      rowData.length == []
      ? businessSubCategoryId
      : rowData.business_type?.category?.id
    );
    axios
      .post(
        `https://b1.bhaaraterp.com/business-sub-category-list-data/`,
        reqbody,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setSubCategory(response?.data.business_sub_category_list);
      })
      .catch((error) => {
        console.log("error page", error.response);
        console.log(error);
      });
  };

  useEffect(() => {
    CategoryList();
  }, []);

  useEffect(() => {
    rowData && StoreList();
    console.log("row data==============>",rowData)
    rowData !== [] && 
    setBrandName(rowData?.title);
    console.log(rowData?.title)
    setDescription(rowData?.description);
    setMetaDescription(rowData?.meta_description);
    setMetaKeyword(rowData?.meta_keyword);
    setMetaTitle(rowData?.meta_title);
    setBusinessFormValidId(rowData?.business_type?.id)

  }, [rowData]);

  const ProductDataList = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/product-brand-list-data/?page=${page}&business_sub_category_id&product_brand_name=`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        // console.log('orderManagementApi===>', response.data.data)
        setProductData(response.data.data);
      })
      .catch((error) => {
        console.log("", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const EditFunction = (row) => {
    setToggleBtn(false);  
    console.log("row data===>",row)
    setBrandId(row?.id);
    setRowData(row); 
    // setProductData(row?.title);
    
  };
  useEffect(() => {
    ProductDataList();
  }, [page]);

  console.log("prduct sub data editing====>",rowData.business_type?.id)
  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">
            Product Management List
          </p>
          <p className="!text-black">View/Search Product Management List</p>
        </div>
        <div className="p-2 pt-5">{/* <CreateResellers /> */}</div>
      </div>
      <div className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between my-2">
          <span className="flex  gap-3">
            <CustomIconButton>
              <FilterAlt onClick={handleOpen} className="!text-black" />
            </CustomIconButton>
            {/* <Search /> */}
            <input
              type="text"
              value={searchQuery}
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>
          <span>
            {/* Add Category Bussiness start */}

            <div className="text-red-500 mx-3">
              <Button variant="outlined" color="primary" onClick={handleOpen1}>
                <AddIcon /> Add Product Brand 
              </Button>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box
                  sx={{ ...style, width: 430 }}
                  className="rounded-2xl !border-0"
                >
                  <div
                    id="child-modal-title "
                    className="!text-black !underline !font-bold !py-3 !text-center !text-xl"
                  >
                    {toggleBtn == true ? "Add Product Brand" : "Update Product Brand"}
                  </div>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Business Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      defaultValue={brandId || ""}
                      value={brandId || ""}
                      onChange={(event)=>setBussinessCategoryId(event.target.value)}
                    >
                      {category?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label !mt-2">
                      Business Sub Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      className="!mt-2"
                      defaultValue={rowData.business_type?.id || ""}
                      value={ businessFormValidId || ""}
                      onChange={(event)=>setBusinessFormValidId(event.target.value)}
                    >
                      {subCategory?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    label="brand name"
                    value={brandName}
                    className="!w-[100%] !mt-2"
                    onChange={(event)=>setBrandName(event.target.value)}
                  />
                  <br />
                  <TextField
                    id="outlined-basic"
                    type="file"
                    size="small"
                    onChange={handleChange}
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={description}
                    onChange={(event)=>setDescription(event.target.value)}
                    label="description"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    id="outlined-basic"
                    size="small"
                    onChange={(event)=>setMetaTitle(event.target.value)}
                    value={metaTitle}
                    variant="outlined"
                    label="meta title"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    id="outlined-basic"
                    size="small"
                    onChange={(event)=>metaDecription(event.target.value)}
                    value={metaDecription}
                    label="Meta Description"
                    variant="outlined"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    id="outlined-basic"
                    label="Meta Keyword"
                    onChange={(event)=>setMetaKeyword(event.target.value)}
                    value={metaKeyword}
                    size="small"
                    variant="outlined"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <Button
                    onClick={handleClose1}
                    variant="contained"
                    color="warning"
                    className="!py-2 !mt-3"
                  >
                    CANCEL
                  </Button>
                  {toggleBtn == true ? (
                    <Button
                      variant="contained"
                      color="success"
                      className="!py-2 !px-5 !font-bold !mt-3 !float-right"
                      onClick={submitCategory}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      className="!py-2 !px-5 !mt-3 !font-bold !float-right"
                      onClick={submitCategory}
                    >
                      UPDATE
                    </Button>
                  )}
                </Box>
              </Modal>
            </div>
          </span>
        </div>
        <TableContainer
          component={Paper}
          // sx={{  }}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2"
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {/* <TableCell className="!text-left !p-2">
                  <Checkbox className="!m-0 !text-black" />
                </TableCell> */}
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  ID
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  CATEGORY NAME
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  IMAGE
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  BUSINESS TYPE
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  SUB BUSINESS TYPE
                </TableCell>
                <TableCell style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="!bg-red">
              {productData &&
                productData
                  ?.filter(
                    (item) =>
                      (item?.title &&
                        item?.title
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())) ||
                      (item?.business_type?.category?.name &&
                        item?.business_type?.category?.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())) ||
                      (item?.value &&
                        item?.value
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())) ||
                      (item?.business_type?.name &&
                        item?.business_type?.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()))
                  )
                  .map((row, index) => (
                    <TableRow key={row.id} className="!text-slate-700">
                      {/* <TableCell
                        className="!text-left !p-2 !text-black"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0 !text-slate-700" />
                      </TableCell> */}
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row.id ? row.id : "--"}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row.title ? row.title : "--"}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row?.image ? (
                          <img src={row?.image} className="h-7 mx-auto w-7" />
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row?.business_type?.category?.name}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row?.business_type?.name}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                        <button onClick={handleOpen1}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditFunction(row)}
                          />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>

            {/* <div
              className="float-right bg-white w-[79.2%] m-2 fixed bottom-[-10px] right-2"
              style={{ borderTop: "1px solid gray" }}
            >
              <TablePagination
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div> */}
          </Table>
        </TableContainer>
        <div
          className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="!float-right">
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductBrand;
