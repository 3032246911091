import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { CustomIconButton } from "../../shared/CustomIconButton";
import axios from "axios";
import Search from "../../shared/Search";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import AddIcon from "@mui/icons-material/Add";

import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
};

const ProductCategory = () => {
  const [productData, setProductData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [page, setPage] = React.useState(1);


  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowData, setRowData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  // Add category Name start
  const [businessCategory, setBusinessSubCatgeory] = useState("");
  const [business_sub_category_id, setSusiness_sub_category_id] = useState("");
  const [product_category_name, setProduct_category_name] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [meta_title, setMeta_title] = useState("");
  const [meta_description, setMeta_description] = useState("");
  const [meta_keyword, setMeta_keyword] = useState("");
  const [subCategory, setSubCategory] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  const [toggleBtn, setToggleBtn] = useState(true);
  // Edit fuctionality end

  const [category, setCategory] = useState([]);
  const handleChange = (e) => {
    console.log(e.target.files);
    setImage(e.target.files[0]);
  };
  const CategoryList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCategory(response.data.business_category_list_data);
        // console.localStorage(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

 
  const submitCategory = () => {
    console.log("imageData==>", image);
    const fd = new FormData();
    fd.append("category_id", categoryId);
    fd.append("business_sub_category_id", business_sub_category_id);
    fd.append("product_category_name", product_category_name);
    fd.append("description", description);
    fd.append("image", image);
    fd.append("meta_title", meta_title);
    fd.append("meta_description", meta_description);
    fd.append("meta_keyword", meta_keyword);

    // const allCateData = {
    //   // category_id: categoryId,
    //   business_sub_category_id: business_sub_category_id,
    //   product_category_name: product_category_name,
    //   description: description,
    //   image: image,
    //   meta_title: meta_title,
    //   meta_description: meta_description,
    //   meta_keyword: meta_keyword,
    // };
    console.log("category Id",categoryId)

    // !categoryId == null
    rowData.length === 0
      ?
      axios
          .post(`https://b1.bhaaraterp.com/add-product-category-data/`, fd, {
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "multipart/formdata",
            },
          })
          .then((response) => {
            // setStore(response?.data.store_list_data);
            toast.success(response.data.message);
            console.log("business store ==>", response.data.data);
          })
          .catch((error) => {
            console.log("error page", error.response);
            console.log(error);
          })
         : // Edit functionality to business category
        axios
          .post(`https://b1.bhaaraterp.com/edit-product-category-data/`, fd, {
            headers: {
              Authorization: localStorage.getItem("token"),
              "Content-Type": "multipart/formdata",
            },
          })
          .then((response) => {
            // setStore(response?.data.store_list_data);
            toast.success(response.data.message);
            handleClose1()
            // console.log("business store ==>", response.data.data)
            
            
          })
          .catch((error) => {
            console.log("error page", error.response);
            console.log(error);
          });
  };

  // console.log("array data===>",rowData.length)
  useEffect(() => {
    console.log("business name===>", rowData?.business_type?.category?.name);
    rowData !== [] && setProduct_category_name(rowData?.title);
    setDescription(rowData?.description);
    // setOption1(rowData?.business_type?.category?.name);
    setBusinessSubCatgeory(rowData?.business_type?.category?.name);
    setSusiness_sub_category_id(rowData?.id);
    setMeta_description(rowData?.meta_description);
    setMeta_keyword(rowData?.meta_keyword);
    setMeta_title(rowData?.meta_title);
    // setImage(rowData?.image)
  }, [rowData]);


  const previousData = () => {
    setPage(page - 1);
  };
  const nextData = () => {
    setPage(page + 1);
  };

  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);




  const StoreList = () => {
    const reqbody = new FormData();
    reqbody.append(
      "business_category_ids",

      rowData.length == []
        // ? rowData.business_type?.category?.id
        // : business_sub_category_id

      ? business_sub_category_id
      : rowData.business_type?.category?.id
    );
    axios
      .post(
        `https://b1.bhaaraterp.com/business-sub-category-list-data/`,
        reqbody,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setSubCategory(response?.data.business_sub_category_list);
      })
      .catch((error) => {
        console.log("error page", error.response);
        console.log(error);
      });
  };

  useEffect(() => {
    CategoryList();
  }, []);

  useEffect(() => {
    business_sub_category_id && StoreList();
  }, [business_sub_category_id]);

  const ProductDataList = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/product-category-list-data/?page=${page}&business_sub_category_id&product_category_name`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log('orderManagementApi===>', response.data.data)
        setProductData(response.data.data);
      })
      .catch((error) => {
        console.log("", error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const EditFunction = (row) => {
    setToggleBtn(false);
    console.log("row data===>",row)
    setCategoryId(row?.id);
    setRowData(row); 
    
  };
  useEffect(() => {
    ProductDataList();
  }, [page]);


  console.log("product list data===>",productData)
 console.log("erp data===>",rowData)
  return (
    <div className=" !bg-slate-100">
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font font-bold">
            Product Management List
          </p>
          <p className="!text-black">View/Search Product Management List</p>
        </div>
        <div className="p-2 pt-5">{/* <CreateResellers /> */}</div>
      </div>
      <div className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600 m-2">
        <div className="flex items-center justify-between my-2">
          <span className="flex  gap-3">
            <CustomIconButton>
              <FilterAlt onClick={handleOpen} className="!text-black" />
              {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className=''
              >
                <Box sx={style} className='!bg-slate-100 !rounded-2xl !w-96'>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div className='text-center font-bold underline underline-offset-1'>Filter </div>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Status : <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className='!h-8 !text-black !border border-black !w-[70%]'

                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Accepted">Accepted</MenuItem>
                      <MenuItem value="Assigned">Assigned</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                      <MenuItem value="Cancelled">Cancelled</MenuItem>
                      <MenuItem value="Delivered">Delivered</MenuItem>
                      <MenuItem value="Refunded">Refunded</MenuItem>
                      <MenuItem value="Returned">Returned</MenuItem>

                    </Select>
                    <br />

                    <div className='flex justify-betwee items-center !mt-3'>
                      <span>Store :</span>
                      <span>
                        <Autocomplete
                          getOptionLabel={(option) => option?.store_name}
                          className='!w-64 !border-black !border-2 !text-black !rounded-xl'

                          renderInput={(params) => (
                            <TextField
                              {...params}

                              label="Choose a country"
                              className='!border-black !text-black !border-2 !rounded-xl'
                              inputProps={{
                                ...params?.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </span>
                    </div>

                    <br />
                    <div className='justify-between items-center gap-x-5'>
                      <Button variant="outlined" className='!bg-primary-700 !text-black' onClick={handleClose}>CANCEL</Button>
                      <Button variant="outlined" className='!bg-primary-700 !text-black float-right '>Apply</Button>
                    </div>
                  </Typography>
                </Box>
              </Modal> */}
            </CustomIconButton>
            {/* <Search /> */}
            <input
              type="text"
              value={searchQuery}
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>
          <span>
            {/* Add Category Bussiness start */}

            <div className="text-red-500 mx-3">
              <Button variant="outlined" color="primary" onClick={handleOpen1}>
                <AddIcon /> Add Category
              </Button>
              <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box
                  sx={{ ...style, width: 430 }}
                  className="rounded-2xl !border-0"
                >
                  <div
                    id="child-modal-title "
                    className="!text-black !underline !font-bold !py-3 !text-center !text-xl"
                  >
                    {toggleBtn == true ? "Add Category" : "Update Category"}
                  </div>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Business Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      defaultValue={rowData.business_type?.id || ""}
                      value={
                        rowData === []
                          ? businessCategory
                          : rowData.business_type?.category?.id
                      }
                      label="Business Category"
                      onChange={(event) =>
                        setSusiness_sub_category_id(event.target.value)
                      }
                    >
                      {category?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label !mt-2">
                      Business Sub Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={rowData.business_type?.id || ""}
                      value={businessCategory}
                      size="small"
                      label="Business Sub Category"
                      className="!mt-2"
                      onChange={(event) =>
                        setSusiness_sub_category_id(event.target.value)
                      }
                    >
                      {subCategory?.map((res) => {
                        return <MenuItem value={res.id}>{res.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    size="small"
                    value={product_category_name}
                    onChange={(event) =>
                      setProduct_category_name(event.target.value)
                    }
                    id="outlined-basic"
                    label="Category Name"
                    variant="outlined"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    onChange={handleChange}
                    id="outlined-basic"
                    type="file"
                    size="small"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    size="small"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    value={meta_title}
                    onChange={(event) => setMeta_title(event.target.value)}
                    id="outlined-basic"
                    label="Title"
                    size="small"
                    variant="outlined"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    value={meta_description}
                    onChange={(event) =>
                      setMeta_description(event.target.value)
                    }
                    id="outlined-basic"
                    // defaultValue="Small"
                    size="small"
                    label="Meta_Description"
                    variant="outlined"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <TextField
                    value={meta_keyword}
                    onChange={(event) => setMeta_keyword(event.target.value)}
                    id="outlined-basic"
                    label="Meta_Keyword"
                    // defaultValue="Small"
                    size="small"
                    variant="outlined"
                    className="!w-[100%] !mt-2"
                  />
                  <br />
                  <Button
                    onClick={handleClose1}
                    variant="contained"
                    color="warning"
                    className="!py-2 !mt-3"
                  >
                    CANCEL
                  </Button>
                  {toggleBtn == true ? (
                    <Button
                      variant="contained"
                      color="success"
                      className="!py-2 !px-5 !font-bold !mt-3 !float-right"
                      onClick={submitCategory}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      className="!py-2 !px-5 !mt-3 !font-bold !float-right"
                      onClick={submitCategory}
                    >
                      UPDATE
                    </Button>
                  )}
                </Box>
              </Modal>
            </div>
          </span>
        </div>
        <TableContainer
          component={Paper}
          // sx={{  }}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2"
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {/* <TableCell className="!text-left !p-2">
                  <Checkbox className="!m-0 !text-black" />
                </TableCell> */}
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  ID
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  CATEGORY NAME
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  IMAGE
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  BUSINESS TYPE
                </TableCell>
                <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  SUB BUSINESS TYPE
                </TableCell>
                <TableCell style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="!bg-red">
              {productData &&
                productData
                  ?.filter(
                    (item) =>
                      (item?.title &&
                        item?.title
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())) ||
                      (item?.business_type?.category?.name &&
                        item?.business_type?.category?.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())) ||
                      (item?.value &&
                        item?.value
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())) ||
                      (item?.business_type?.name &&
                        item?.business_type?.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()))
                  )
                  .map((row, index) => (
                    <TableRow key={row.id} className="!text-slate-700">
                      {/* <TableCell
                        className="!text-left !p-2 !text-black"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0 !text-slate-700" />
                      </TableCell> */}
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row.id ? row.id : "--"}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row.title ? row.title : "--"}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row?.image ? (
                          <img src={row?.image} className="h-7 mx-auto w-7" />
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row?.business_type?.category?.name}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        {row?.business_type?.name}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                        <button onClick={handleOpen1}>
                          <EditIcon
                            className="!text-xl !cursor-pointer !text-green-600"
                            onClick={() => EditFunction(row)}
                          />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>

            {/* <div
              className="float-right bg-white w-[79.2%] m-2 fixed bottom-[-10px] right-2"
              style={{ borderTop: "1px solid gray" }}
            >
              <TablePagination
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div> */}
          </Table>
        </TableContainer>
        <div
          className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
          style={{ marginRight: "0px" }}
        >
          <button onClick={nextData} className="!float-right">
            <ArrowForwardIosIcon />
          </button>
          <button onClick={previousData} className="float-right">
            <ArrowBackIosIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
