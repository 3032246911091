import React, { useEffect, useRef, useState } from "react";
import TableBody from "@mui/material/TableBody";
import axios from "../../config/axios";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Search from "../../shared/Search";
import { CustomIconButton } from "../../shared/CustomIconButton";
import { toast } from "react-toastify";
import { Active, Inactive } from "../../shared/ActivaInactive";
import AddStore from "./AddStore";
import Loader from "../../shared/Loader";
import { Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Popup from "reactjs-popup";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

// start
import Table from "@mui/material/Table";

import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// QR Code start
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";

// import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
// QR Code end

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
// end

const Store = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(true);
  const [page, setPage] = useState(1);

  const box1 = useRef();

  const previousData = () => {
    setPage(page - 1);
  };
  const nextData = () => {
    setPage(page + 1);
  };

  const [open1, setOpen1] = useState(false);
  const closeModal1 = () => setOpen1(false);

  const closeModal = () => {
    // box1.current.style.display="none"
    box1.current.style.display = "none";
  };

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    console.log("hello", event.is_store_active_status);

    event.is_store_active_status == true
      ? axios
          .post(
            `https://b1.bhaaraterp.com/store-data-deactivate/`,
            { store_id: event?.id },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            console.log("succes");
            storeApi(); // toast.success(response.data.message);
          })
          .catch((error) => {
            console.log("", error.response);
            console.log(error);
            // alert(error.message);
          })
      : axios
          .post(
            `https://b1.bhaaraterp.com/store-data-activate/`,
            { store_id: event?.id },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            console.log("succes");
            storeApi(); // toast.success(response.data.message);
          })
          .catch((error) => {
            console.log("", error.response);
            console.log(error);
            // alert(error.message);
          });
  };

  // console.log(searchQuery)

  const storeApi = () => {
    axios
      .get(`https://b1.bhaaraterp.com/store-data-list/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response?.data.store_list_data);
        console.log("data==>", response?.data.store_list_data);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    storeApi();
  }, []);

  return (
    <>
      <div className="!bg-white">
        <div className="flex justify-between !bg-slate-100">
          <div className="p-2">
            <p className="text-xl font font-bold !text-black">Business List</p>
            <p className="!text-black">View/Search Store list</p>
          </div>
          <div className="p-2 pt-5  ">{/* <AddStore /> */}</div>
        </div>
        <div className="flex flex-col !mt-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2 ">
          <div className="flex items-center p-2 h-14 !bg-slate-100 border-zinc-600">
            <CustomIconButton style={{ border: "1px solid black" }}>
              <FilterAltIcon className="bg-white text-black" />
            </CustomIconButton>
            <input
              type="text"
              value={searchQuery}
              className="!border-3 !border-black !w-64 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </div>

          <div className="w-[77vw] overflow-auto ">
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table
                size="small"
                aria-label="a dense table"
                className=""
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      <div className="w-36">Store Qr Code</div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      <div className="!w-52 text-center">STORE NAME</div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      EMAIL
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      <div className="w-32" >
                        MOBILE NO.
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      <div className="w-24">PAN NO.</div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      GSTN
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      WEBSITE
                    </TableCell>
                    <TableCell align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold">
                      <div className="!w-40 text-center">REAL-TIME-SETTING</div>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      STATUS
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="!overflow-y-auto !vh-[100%]">
                  {data &&
                    data
                      ?.filter(
                        (item) =>
                          (item?.store_admin_email &&
                            item?.store_admin_email
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())) ||
                          (item?.store_name &&
                            item?.store_name
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())) ||
                          (item?.gst_number &&
                            item?.gst_number.includes(searchQuery))
                      )
                      .map((store) => (
                        <TableRow
                          key={store?.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            {store?.store_qr_code_unique_no}
                            {/* <RemoveRedEyeIcon
                          className="!text-blue-500"
                          onClick={handleOpen}
                        />
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          className="!border-0"
                        >
                          <Box sx={style} className="!border-0">
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <div className="float-right" onClose={handleClose}>X</div>
                            </Typography>
                            <Typography id="modal-modal-description">
                              <img
                                src={store?.store_qr_code_image}
                                className="!w-[100%] !h-[100%]"
                                style={{ backgroundSize: "cover" }}
                              />
                            </Typography>
                          </Box>
                        </Modal> */}
                            <Popup
                              trigger={
                                <button className="button text-white rounded">
                                  <RemoveRedEyeIcon
                                    className="!text-blue-500"
                                    onClick={handleOpen}
                                  />
                                </button>
                              }
                              modal
                            >
                              <div
                                className=" text-black rounded-xl p-4"
                                style={{
                                  border: "1px solid gray",
                                  background:
                                    "linear-gradient(180deg, rgba(2, 0, 40, 1) 0%, rgba(1, 0, 75, 1) 49%, rgba(2, 0, 95, 1) 65%, rgba(2, 0, 120, 1) 100%)",
                                }}
                                ref={box1}
                              >
                                <div
                                  className="absolute cursor-pointer right-[-9px] top-[-7px] rounded"
                                  onClick={closeModal}
                                >
                                  <span className="text-white bg-black rounded-2xl pb-2 p-1">
                                    <CloseIcon />
                                  </span>
                                </div>
                                <div className="py-2">
                                  <img
                                    src={store?.store_qr_code_image}
                                    className="!w-[200px] !h-[200px] rounded"
                                    style={{ backgroundSize: "cover" }}
                                  />
                                </div>
                              </div>
                            </Popup>
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            {store?.store_name ? store?.store_name : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            {store?.store_admin_email
                              ? store?.store_admin_email
                              : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            {store?.store_admin_mobile
                              ? store?.store_admin_mobile
                              : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            {store?.pan_number ? store?.pan_number : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            {store?.gst_number ? store?.gst_number : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            {store?.website ? store?.website : "--"}
                          </TableCell>
                          <TableCell className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            <Popup
                              trigger={
                                <button className="button bg-blue-400 !text-[10px] p-1 text-white rounded">
                                  SETUP
                                </button>
                              }
                              modal
                            >
                              <div
                                className="bg-white text-black rounded-xl h-52 w-80 p-4"
                                style={{ border: "1px solid gray" }}
                                ref={box1}
                              >
                                <div className="text-gray-600 font-bold">
                                  REAL TIME SYNC SETTING
                                </div>
                                <div
                                  className="absolute cursor-pointer right-2 top-4 bg-gray-500 px-3 rounded text-red-600"
                                  onClick={closeModal}
                                >
                                  X
                                </div>
                                <hr />
                                <div className="py-3">
                                  <p>
                                    <span className="text-gray-700">
                                      Store Id
                                    </span>{" "}
                                    : {"hello"}
                                  </p>
                                  <p>
                                    <span className="text-gray-700">
                                      Store Name
                                    </span>{" "}
                                    : {"hello"}
                                  </p>

                                  <p>
                                    <span className="text-gray-700">
                                      Order Sync Active
                                    </span>{" "}
                                    : {"hello"}
                                  </p>
                                  <p>
                                    <span className="text-gray-700">
                                      Order Sync Active
                                    </span>{" "}
                                    : {"hello"}
                                  </p>
                                </div>
                              </div>
                            </Popup>
                          </TableCell>
                          <TableCell style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}>
                            <Switch
                              checked={store?.is_store_active_status || false}
                              onChange={() => handleChange(store)}
                              // inputProps={{ 'aria-label': 'controlled' }}
                              label="Jason Killian"
                              size="small"
                            />
                          </TableCell>
                          <TableCell className="!text-center">
                            <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                            <button>
                              <EditIcon className="!text-xl !cursor-pointer !text-green-600" />
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div
            className="!float-right relative bg-white w-[100%] mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-400 !flex-col !justify-end !items-center"
            style={{ marginRight: "0px" }}
          >
            <button onClick={nextData} className="!float-right">
              <ArrowForwardIosIcon />
            </button>
            <button onClick={previousData} className="float-right">
              <ArrowBackIosIcon />
            </button>
          </div>
          {/* <div className="float-right py-2 gap-x-4 bg-white w-[79.15%]fixed right-2" style={{borderTop:"1px solid gray"}}>
            <button onClick={PreviousData} className="float-right text-start relative right-8">
              {" "}
              <ArrowBackIosIcon className="!text-gray-400" />
            </button>
            <button onClick={nextData} className="float-right text-end relative right-[-8px]">
              <ArrowForwardIosIcon className="!text-gray-400"/>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Store;
