import { FilterAlt } from "@mui/icons-material";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { CustomIconButton } from "../../shared/CustomIconButton";
import Search from "../../shared/Search";
import { DownloadTableExcel } from "react-export-table-to-excel";
import excel from "../../assets/excel.svg";
import { toast } from "react-toastify";

const CandidateData = () => {
  const [data, setData] = useState();

  const tableRef = useRef(null);
  const CandidateDataFileList = () => {
    axios
      .get(`http://45.64.156.214:9897/candidate-export-data-in-excel/`)
      .then((response) => {
        setData(response?.data.data);
        // toast(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        toast(error.message);
      });
  };

  useEffect(() => {
    CandidateDataFileList();
  }, []);

  return (
    <div className="m-4">
      <div className="flex flex-col !my-3 !rounded  bg-secondary border-secondary shadow-card shadow-zinc-600">
        <div className="flex justify-between items-center p-2 h-14 border-zinc-600">
          <div className="flex items-center">
            <CustomIconButton>
              <FilterAlt />
            </CustomIconButton>
            <Search />
          </div>
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <div className="!bg-white !rounded">
              <img src={excel} alt="" className="h-10" />
            </div>
          </DownloadTableExcel>
        </div>
      </div>
      <TableContainer component={Paper} sx={{ maxWidth: 1420 }}>
        <Table className="overflow-auto !text-white" ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Employee</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Mobile No</TableCell>
              <TableCell align="center">Gender</TableCell>
              <TableCell align="center">Date Of Birth</TableCell>
              <TableCell align="center">Current Address</TableCell>
              <TableCell align="center">Permanent Address</TableCell>
              <TableCell align="center">Highest Qualification</TableCell>
              <TableCell align="center">Profile Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.employee}</TableCell>
                  <TableCell align="center">{item.first_name}</TableCell>
                  <TableCell align="center">{item.last_name}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.mobile_no}</TableCell>
                  <TableCell align="center">{item.gender}</TableCell>
                  <TableCell align="center">{item.dob}</TableCell>
                  <TableCell align="center">{item.current_address}</TableCell>
                  <TableCell align="center">{item.permanent_address}</TableCell>
                  <TableCell align="center">
                    {item.highest_qualification}
                  </TableCell>
                  <TableCell align="center">{item.profile_link}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CandidateData;
