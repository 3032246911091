import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const Active = () => {
  return (
    <>
      <span className="bg-zinc-300 w-24 rounded-full text-black flex justify-center items-center p-px m-1">
        <FiberManualRecordIcon color="success" className="!text-sm mr-1" />
        Active
      </span>
    </>
  );
};

export const Inactive = () => {
  return (
    <>
      <span className="bg-zinc-300 w-24 text-black rounded-full flex justify-center items-center p-px m-1">
        <FiberManualRecordIcon color="error" className="!text-sm mr-1" />
        Inactive
      </span>
    </>
  );
};

export const Unmaped = () => {
  return (
    <>
      <span className="bg-zinc-300 w-28 text-black rounded-full flex justify-center items-center p-px m-1">
        <FiberManualRecordIcon color="warning" className="!text-sm mr-1" />
        Unmaped
      </span>
    </>
  );
};
