import React, { useEffect } from "react";
// import axios from "../../config/axios";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import { Switch } from "@mui/material";
import Search from "../../shared/Search";
// import { Active, Inactive } from "../../shared/ActivaInactive";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import DeleteIcon from "@mui/icons-material/Delete";
import { CustomIconButton } from "../../shared/CustomIconButton";
import CreateNewCombo from "./CreateNewCombo";
import SimpleSnackbar from "../../shared/Snackbar";

const VariantCombinations = () => {
  //   const [data, setData] = useState();

  useEffect(
    () => {
      //   axios
      //     .post(
      //       `https://b1.bhaaraterp.com/varient-values-data/`,
      //       {},
      //       {
      //         headers: {
      //           Authorization: "5bec3ae37bd14b0db36c574abdaccd60",
      //         },
      //       }
      //     )
      //     .then((response) => {
      //       setData(response?.data.varient_value_list_data);
      //       // alert(response.data.message);
      //     })
      //     .catch((error) => {
      //       console.log("", error.response);
      //       console.log(error);
      //       // alert(error.message);
      //     });
    },
    // eslint-disable-next-line
    []
  );
  // console.log("Data", data);
  return (
    <>
      <div className="!bg-gray-100">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl text-black font font-bold">Variant Combinations</p>
            <p className="text-black">Manage Variant Groups</p>
          </div>
          <CreateNewCombo />
        </div>

        <div className="flex flex-col !my-3 !rounded  bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center bg-gray-100 p-2 h-14 border-zinc-600">
            <CustomIconButton>
              <FilterAltIcon className="text-black" />
            </CustomIconButton>
            <Search />
          </div>
          <div className="flex justify-between items-center border-zinc-600 bg-white border-b-2">
            <div className="flex w-full p-4">
              <p>Group Name</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Variant Value</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Status</p>
            </div>

            <div className="flex justify-center w-full">
              <p>Action</p>
            </div>
          </div>
          
          {/* <SimpleSnackbar /> */}
          {/* {data &&
            data.map((varient) => {
              return (
                <div
                  key={varient.id}
                  className="flex justify-between items-center border-zinc-600 border-b"
                >
                  <div className="flex w-full p-4">
                    <p>{varient.variant_name}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{varient.value}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{varient.status === 1 ? <Active /> : <Inactive />}</p>
                  </div>
                  <div className="flex justify-center w-full">
                    <Switch
                      color="warning"
                      // id={checkbox.id}
                      // name={checkbox.id}
                      // onChange={formik.handleChange}
                      // checked={formik.values[checkbox.id]}
                    />
                    <AssignVariant variant_id={varient.id} />
                    <CustomIconButton
                    // onClick={() => {
                    //   navigate(`/store/${store.id}`);
                    // }}
                    >
                      <BorderColorIcon />
                    </CustomIconButton>
                    <CustomIconButton
                    // onClick={() => {
                    //   navigate(`/store/${store.id}`);
                    // }}
                    >
                      <DeleteIcon />
                    </CustomIconButton>
                  </div>
                </div>
              );
            })} */}
        </div>
      </div>
    </>
  );
};

export default VariantCombinations;
