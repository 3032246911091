import { FilterAlt, Visibility } from "@mui/icons-material";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../shared/CustomButton";
import { CustomIconButton } from "../../shared/CustomIconButton";
import Search from "../../shared/Search";
import DeleteGroup from "./GroupDetails/DeleteGroup";

const Groups = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [data, setData] = useState([]);
  const [deleted, setDeleted] = useState([]);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ResellersListData = () => {
    axios
      .get(`https://b1.bhaaraterp.com/group-and-permission/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response.data.groups);
      })
      .catch((error) => {
        console.log("", error);
      });
  };
  useEffect(() => {
    ResellersListData();
  }, [deleted]);

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl text-black font-bold">Groups List</p>
            <p className="text-black">View/Search Groups List</p>
          </div>
          <div className="p-2 pt-5">
            <CustomButton
              className="px-5"
              onClick={() => navigate("/add-group")}
            >
              + Add Group
            </CustomButton>
          </div>
        </div>
        <div className="flex flex-col !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center bg-gray-100 rounded py-2">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <Search />
          </div>
          <TableContainer component={Paper}
          // sx={{  }}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2">
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {/* <TableCell className="!text-left !p-2">
                    <Checkbox className="!m-0" />
                  </TableCell> */}
                  <TableCell 
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">Group ID</TableCell>
                  <TableCell style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">Group Name</TableCell>
                  <TableCell style={{
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index + 1}
                      className="!cursor-pointer"
                      //
                    >
                      {/* <TableCell
                        className="!text-left !p-2"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0" />
                      </TableCell> */}
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>{row.id}</TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}>{row.group}</TableCell>
                      <TableCell className="!text-center !text-[12px]"
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}>
                        <CustomIconButton
                          onClick={() => navigate(`/group-detail/${row?.id}`)}
                        >
                          <Visibility />
                        </CustomIconButton>
                        <DeleteGroup Group={row} setDeleted={setDeleted} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[6]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Groups;
