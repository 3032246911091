import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { CustomIconButton } from "../../../../shared/CustomIconButton";

const DeleteGroup = ({ Group, setDeleted }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const status = () => {
    setDeleted(true);
    setOpen(false);
  };
  const emptyfunction = () => {};

  const handleDelete = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/delete-group-permission/`,
        { group_id: Group?.id },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data.response);
        response.data.response === "Group is Successfully Deleted"
          ? status()
          : toast.warning("Something went Wrong");
        response.data.response === "Group is Successfully Deleted"
          ? toast.success(response.data.response)
          : emptyfunction();
      })
      .catch((error) => {
        console.log("", error);
      });
  };

  return (
    <>
      <CustomIconButton onClick={handleClickOpen}>
        <Delete />
      </CustomIconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete"} {Group?.group}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the group "{Group?.group}" ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            No
          </Button>
          <Button onClick={handleDelete} variant="contained" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteGroup;
