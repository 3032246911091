import React, { useState } from "react";
import { Avatar, Button, Divider, Menu, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logobharat.png";
import message from "../../assets/Header/Message.svg";
import Search from "../Search";
import axios from "axios";
import { toast } from "react-toastify";
import { Notifications } from "../Notifications";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const user_name = localStorage.getItem("user_name");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
  };
  const handleLogoutReseller = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/reseller_app/reseller-log-out/`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message);
      });
  };

  return (
    <>
      <div className="flex flex-row h-16 shadow border-white border-b">
        <div className="flex items-center w-[14.5rem] mx-3">
          <img
            src={logo}
            alt="brandlogo"
            className="cursor-pointer h-14 mr-1 p-1"
          />
          <h1 className="text-xl font-bold w-52 text-white cursor-pointer whitespace-nowrap">
            Super-Admin
          </h1>
        </div>

        <div className="flex items-center justify-between w-full text-zinc-700 bg-white">
          <div className="ml-7">
            {/* <Search className="!border-none" /> */}
          </div>

          <div className="flex items-center justify-center cursor-pointer rounded-md px-3">
            <div className="flex gap-5 mr-10">
              <Notifications />
              <img src={message} alt="" />
            </div>
            {role === "admin" ? (
              <>
                <Avatar alt="S" src="broken.jpg" className=" !bg-gray-600 " />
                <div className="px-2 py-1 text-zinc-700" onClick={handleClick}>
                  <h1 className="font-semibold !text-black">Dashboard</h1>
                  <h1 className="text-xs mb-1">Super Admin</h1>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="!absolute !top-3 !right-40 !shadow-sm"
                  disableScrollLock
                  elevation={10}
                >
                  <MenuItem className="hover:!rounded !mx-1 hover:!bg-secondary hover:!text-white">
                    Profile
                  </MenuItem>
                  <MenuItem className="hover:!rounded !mx-1 hover:!bg-secondary hover:!text-white">
                    Account Setting
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    className="hover:!rounded !mx-1 hover:!bg-secondary hover:!text-white"
                    onClick={handleLogout}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Avatar alt="D" src={logo} className=" !bg-gray-400" />
                <div className="px-2 py-1 text-zinc-700" onClick={handleClick}>
                  <h1 className="font-semibold capitalize">{role}</h1>
                  <h1 className="text-xs mb-1">{user_name}</h1>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  className="!absolute !right-40 !top-3"
                  disableScrollLock
                  elevation={10}
                >
                  <div className="flex flex-col gap-2 justify-center items-center w-52">
                    <Avatar
                      alt={user_name}
                      src="broken.jpg"
                      className="!h-16 !w-16"
                    />
                    <div className="flex flex-col items-center">
                      <p>{user_name}</p>
                      <p className="text-xs">{email}</p>
                    </div>

                    <div className="flex justify-between w-full gap-2 p-2 border-t-2 border-darkborder">
                      <Link to="/resellers/profile">
                        <Button variant="contained" className="!capitalize">
                          Profile
                        </Button>
                      </Link>

                      <Button
                        variant="contained"
                        color="black"
                        className="!capitalize text-black"
                        onClick={handleLogoutReseller}
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                </Menu>
              </>
            )}
            {/* <KeyboardArrowDownIcon /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
