import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button, IconButton, Paper } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomButton } from "../../../shared/CustomButton";
import CustomInput from "../../../shared/CustomInput";

const GroupDetail = () => {
  const { id } = useParams();
  const [groupData, setGroupData] = useState([]);
  const [permissionData, setPermissionData] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const groupDetail = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/group-details/`,
        { group_id: id },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setGroupData(response.data);
        setSelectedPermissions(response.data.permission?.map((item) => item.id.toString()));
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    groupDetail();
  }, [id]);

  const PermissionList = () => {
    axios
      .get(`https://b1.bhaaraterp.com/list-of-permission/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setPermissionData(response.data?.permissions);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    PermissionList();
  }, []);
  console.log();
  const initialValues = {
    group_name: groupData?.group,
    permissions: "",
    remove_permissions: "",
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,

    onSubmit: (values, action) => {
      const reqbody = {
        group_id: id,
        permission: selectedPermissions,
      };

      axios
        .post(`https://b1.bhaaraterp.com/update-group-permission/`, reqbody, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          toast.success(response.data.response);
          response.data.response === "Group is Successfully Created"
            ? navigate("/groups")
            : navigate("#");
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          toast.error(error.message);
        });
      // action.resetForm();
    },
  });

  const handleNext = (event) => {
    if (!selectedPermissions.includes(formik.values.permissions)) {
      setSelectedPermissions((item) =>
        selectedPermissions.concat(formik.values.permissions)
      );
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((item) => item !== formik.values.permissions)
      );
    }
  };
  const handleBack = (event) => {
    setSelectedPermissions(
      selectedPermissions.filter(
        (item) => !formik.values["remove_permissions"].includes(item)
      )
    );
    // setSelectedPermissions(
    //   selectedPermissions.filter(
    //     (item) => item !== formik.values.remove_permissions
    //   )
    // );
  };

  // console.log(
  //   // permissionData,
  //   // selectedPermissions,
  //   "selected",
  //   // permissionData?.filter(
  //   //   (item) => !selectedPermissions.includes(item.id.toString()),
  //   // formik.values["remove_permissions"]
  //   permissionData?.filter((item) =>
  //     selectedPermissions.includes(item.id.toString())
  //   )
  // );
  const handleSelectAll = () => {
    setSelectedPermissions(permissionData.map((item) => item.id.toString()));
  };

  console.log(selectedPermissions);
//   console.log(selectedPermissions.map((item) => item.id.toString()));

  return (
    <>
      <div className="flex p-4 w-full">
        <form onSubmit={formik.handleSubmit} className="flex gap-3 w-full">
          <Paper className="flex flex-col p-5 !py-4 w-3/4">
            <p className="text-2xl text-center">{groupData?.group}</p>
            <CustomInput
              type="text"
              name="group_name"
              id="group_name"
              value={formik.values["group_name"]}
              onChange={formik.handleChange}
              placeholder="Enter Group Name"
              label="Name* : "
              className2="flex whitespace-nowrap mt-2"
            />

            <div className="flex gap-5 mt-5 justify-center items-center">
              <div className="flex flex-col gap-3">
                <p className="p-2 rounded bg-black font-semibold text-center">
                  Available Permission
                </p>
                <select
                  name="permissions"
                  id="permissions"
                  multiple
                  className="!text-black !outline-none h-96 w-96 p-1"
                  value={formik.values["permissions"]}
                  onChange={formik.handleChange}
                >
                  {selectedPermissions !== []
                    ? permissionData
                        ?.filter(
                          (item) =>
                            !selectedPermissions.includes(item.id.toString())
                        )
                        .map((permissionData) => {
                          return (
                            <option
                              key={permissionData.id}
                              value={permissionData.id}
                            >
                              {permissionData.permission_name}
                            </option>
                          );
                        })
                    : selectedPermissions.map((permissionData) => {
                        return (
                          <option
                            key={permissionData.id}
                            value={permissionData.id}
                          >
                            {permissionData.permission_name}
                          </option>
                        );
                      })}
                </select>
                <Button
                  variant="contained"
                  onClick={handleSelectAll}
                  className="p-2 rounded bg-dark hover:bg-black font-semibold text-center"
                >
                  Select All
                </Button>
              </div>

              <div className="flex flex-col">
                <IconButton onClick={handleNext}>
                  <ArrowForward />
                </IconButton>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              </div>
              <div className="flex flex-col gap-3">
                <p className="p-2 rounded bg-black font-semibold text-center">
                  Selected Permission
                </p>
                <select
                  name="remove_permissions"
                  id="remove_permissions"
                  multiple
                  value={formik.values["remove_permissions"]}
                  onChange={formik.handleChange}
                  className="!text-black !outline-none h-96 w-96 p-1"
                >
                  {permissionData
                    ?.filter((item) =>
                      selectedPermissions.includes(item.id.toString())
                    )
                    .map((permissionData, index) => {
                      return (
                        <option key={index} value={permissionData.id}>
                          {permissionData.permission_name}
                        </option>
                      );
                    })}
                </select>
                <Button
                  variant="contained"
                  onClick={() => setSelectedPermissions([])}
                  className="p-2 rounded bg-dark hover:bg-black font-semibold text-center"
                >
                  Remove All
                </Button>
              </div>
            </div>
          </Paper>
          <Paper className="w-1/4 flex items-center justify-center">
            <CustomButton type="submit" className="!px-10">
              Update Group
            </CustomButton>
          </Paper>
        </form>
      </div>
    </>
  );
};

export default GroupDetail;
