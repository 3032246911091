import React, { useEffect, useState } from "react";
import axios from "../../../config/axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Search from "../../../shared/Search";
import { Active, Inactive, Unmaped } from "../../../shared/ActivaInactive";
import { CustomButton } from "../../../shared/CustomButton";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import AddSubCategory from "./addSubCategory";
import CustomToggleButton from "../../../shared/CustomToggleButton";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const BusinessSubCategory = () => {
  const [data, setData] = useState();
  const [store, setStore] = useState();
  const [businessSubCategory] = useState([]);
  const [id,setId]=useState(54)

  const StoreList = () => {
    axios
      .get(`https://b1.bhaaraterp.com/store-data-list/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setStore(response?.data.store_list_data);
        console.log(response?.data.store_list_data)
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };
  const handleChange = (event) => {
    event.target.checked === true
      ? businessSubCategory.push(event.target.value)
      : businessSubCategory.pop(event.target.value);
    console.log("Category Array", businessSubCategory);
  };

  const handleActivateDeactivate = (event) => {
    event.target.checked === true
      ? ActivateBusinessCategory()
      : DeactivateBusinessCategory();
  };

  const ActivateBusinessCategory = () => {
    const reqBodyForActivate = {
      business_sub_category: businessSubCategory,
    };

    console.log("Activate", reqBodyForActivate);

    axios
      .post(
        `https://b1.bhaaraterp.com/activate-business-sub-category-store/`,
        { ...reqBodyForActivate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        BuisenesSubCategoryList();
        reqBodyForActivate.business_sub_category.length !== 0
          ? toast.success(
              "Business Sub Category with ID " +
                reqBodyForActivate.business_sub_category +
                " are Activated"
            )
          : toast.warning("Please Select A Business Sub Category First");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        alert(error.message);
      });
  };

  const DeactivateBusinessCategory = () => {
    const reqBodyForDeactivate = {
      business_sub_category: businessSubCategory,
    };

    axios
      .post(
        `https://b1.bhaaraterp.com/deactivate-business-sub-category-store/`,
        { ...reqBodyForDeactivate },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        BuisenesSubCategoryList();

        reqBodyForDeactivate.business_sub_category.length !== 0
          ? toast.success(
              "Business Sub Category with ID " +
                reqBodyForDeactivate.business_sub_category +
                " are Deactivated"
            )
          : toast.warning("Please Select A Business Sub Category First");
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        alert(error.message);
      });
  };

  const BuisenesSubCategoryList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/business-sub-category-list-data/`,
        {business_category_ids:id},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response?.data.business_sub_category_list);
        console.log("bussiness sub category data list===>",response)
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        console.log("error not allow")
        // alert(error.message);
      });
  };

  useEffect(() => {
   
    BuisenesSubCategoryList();
    StoreList();
  }, [id]);
  const initialValues = {
    store: "",
    business_sub_category: businessSubCategory,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        store: values.store_id,
        business_sub_category: businessSubCategory,
      };
      console.log("ReqBody", reqBody);

      axios
        .post(
          `https://b1.bhaaraterp.com/map-business-sub-category-store/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          // alert(response.data.message);
          BuisenesSubCategoryList();
          reqBody.business_sub_category.length !== 0
            ? toast.success(
                "Business Sub Category with IDs " +
                  reqBody.business_sub_category +
                  " are maped to " +
                  reqBody.store
              )
            : toast.warning("Please Select A Business Sub Category First");
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          // alert(error.message);
        });
      action.resetForm();
    },
  });

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl font text-gray-800 font-bold">Sub Category List</p>
            <p className="text-gray-700">View/Search sub-category list</p>
          </div>
          {/* <div className="!border border-black"> */}
          <AddSubCategory />
          {/* </div> */}
          
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col !my-3 !rounded bg-dark border-secondary shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
            <div className="flex">
              <CustomIconButton>
                <FilterAltIcon className="text-black" />
              </CustomIconButton>
              <Search />
            </div>
            <CustomToggleButton
              id="activate-deactivate"
              name="activate-deactivate"
              value=""
              onChange={handleActivateDeactivate}
            />
            <div className="flex">
              <select
                className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border bg-white border-zinc-700"
                name="store_id"
                id="store_id"
                // value={formik.values["store_id"]}
                // onChange={formik.handleChange}
                value={id}
                onChange={(e)=>setId(e.target.value)}
              >
                <option value="">Please select store from here</option>
                {store &&
                  store.map((store) => {
                    return (
                      <option key={store.id} value={store.id}>
                        {store.store_name}
                      </option>
                    );
                  })}
              </select>
              {/* <CustomButton type="Submit" className="!p-1.5">
                Assign Category
              </CustomButton> */}
            </div>
          </div>
          {/* <div className="flex justify-between items-center border-zinc-600 bg-secondary border-b-2">
            <div className="flex w-full items-center p-4">
              <input
                type="checkbox"
                className="border-gray-300 rounded h-5 w-5 mr-5"
              />
              <p>Sub Category Name</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Parent Category</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Catgory Code</p>
            </div>
            <div className="flex justify-center w-full p-4">
              <p>Status</p>
            </div>
            <div className="flex justify-center w-2/3 p-4">
              <p>Action</p>
            </div>
          </div> */}
          {/* {data &&
            data.length > 0 &&
            data.map((subcat) => {
              return (
                <div
                  key={subcat.id}
                  className="flex justify-between items-center border-zinc-600 border-b"
                >
                  <div className="flex items-center w-full p-4">
                    <input
                      type="checkbox"
                      id={subcat.id}
                      name={subcat.id}
                      value={subcat.id}
                      onChange={handleChange}
                      className="border-gray-300 rounded h-5 w-5 mr-5"
                    />
                    <p>{subcat.name}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>Parent Category</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    <p>{subcat.id}</p>
                  </div>
                  <div className="flex justify-center w-full p-4">
                    {subcat.map_status === null ? (
                      <Unmaped />
                    ) : subcat.map_status === 1 ? (
                      <Active />
                    ) : (
                      <Inactive />
                    )}
                  </div>
                  <div className="flex justify-center w-2/3 px-3">
                    <CustomIconButton>
                      <BorderColorIcon />
                    </CustomIconButton>
                    <CustomIconButton>
                      <DeleteIcon />
                    </CustomIconButton>
                  </div>
                </div>
              );
            })} */}

          <div className="">
            <TableContainer
              component={Paper}
              // sx={{  }}
              sx={{ width: "100%", maxHeight: 440 }}
              className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Sub Category Name
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Parent Category
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Catgory Code
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      className="!text-center !text-gray-800 !font-bold"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((cat) => {
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            component="th"
                            scope="row"
                          >
                            {cat.name}
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            Parent Category
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            {cat.id}
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              borderRight: "1px solid #D0D0D0",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            <div className=" mx-auto items-center justify-center ">
                              {cat.map_status === null ? (
                                <Unmaped />
                              ) : cat.map_status === 1 ? (
                                <Active />
                              ) : (
                                <Inactive />
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            className="!text-center !text-[12px]"
                            style={{
                              fontFamily: "'Poppins', sans-serif",
                            }}
                          >
                            <div className=" mx-auto justify-center text-center items-center">
                              <CustomIconButton>
                                <BorderColorIcon className="!text-center text-green-500"/>
                              </CustomIconButton>
                              <CustomIconButton>
                                <DeleteIcon className="!text-center text-red-500" />
                              </CustomIconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </form>
    </>
  );
};

export default BusinessSubCategory;
