import React from "react";
import Header from "../shared/Header";
import Sidebar from "../shared/Sidebar";

const Layout = ({ component }) => {
  return (
    <>
      <div
        className="sticky bg-secondary top-0 w-full"
        style={{
          background:"linear-gradient(180deg, rgba(2, 0, 40, 1) 0%, rgba(1, 0, 75, 1) 49%, rgba(2, 0, 95, 1) 65%, rgba(2, 0, 120, 1) 100%)"
          // background:
          //   " linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(141,190,122,1) 49%, rgba(176,189,98,1) 65%, rgba(253,187,45,1) 100%)",
        }}
      >
        <Header />
        <div
          className="flex bg-secondary"
          style={{
            // background:
            //   " linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(141,190,122,1) 49%, rgba(176,189,98,1) 65%, rgba(253,187,45,1) 100%)",
            background:"linear-gradient(180deg, rgba(2, 0, 40, 1) 0%, rgba(1, 0, 75, 1) 49%, rgba(2, 0, 95, 1) 65%, rgba(2, 0, 120, 1) 100%)"
          }}
        >
          <Sidebar />
          <div className="text-gray-400 w-full bg-secondary m-2 !mx-4" style={{width:"79%"}}>
            {component}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
