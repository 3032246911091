import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import { useFormik } from "formik";
import axios from "../../../config/axios";
import { CustomButton } from "../../../shared/CustomButton";

const AddNewVariant = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [newTextField, setNewTextField] = useState([
    {
      id: "variant_values",
      label: "Variant Value",
    },
  ]);

  const textField = () => {
    setNewTextField([
      ...newTextField,
      { value: "variant_values" + 1, label: "Variant Value" + 1 },
    ]);
  };

  const initialValues = {
    variant_name: "",
    variant_values: [],
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        varient_name: values.variant_name,
        varient_values_ids: values.variant_values,
      };

      axios
        .post(
          `https://b1.bhaaraterp.com/add-varient-value-data/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          // alert(response.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          // alert(error.message);
        });
      action.resetForm();
    },
  });

  return (
    <>
      <CustomButton onClick={handleOpen}>+ Add New Variant</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 text-white bg-popup outline-none -translate-x-1/2 -translate-y-1/2 w-1/3 shadow-md p-6 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col text-black mx-auto gap-2"
          >
            <p className="text-2xl underline font-semibold text-white text-center my-5">
              Add Variant
            </p>
            <p className="text-left text-white">Variant Name</p>
            <input
              autoComplete="off"
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="variant_name"
              id="variant_name"
              value={formik.values["variant_name"]}
              onChange={formik.handleChange}
              placeholder="Ex: Color"
            />

            {newTextField.map((add, index) => {
              return (
                <div key={add.id}>
                  <p className="text-left text-white">{add.label}</p>
                  <input
                    autoComplete="off"
                    className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                    name={"value" + index}
                    id={"value" + index}
                    value={formik.values["value" + index]}
                    onChange={formik.handleChange}
                    placeholder={"value" + index.toString()}
                  />
                </div>
              );
            })}
            <div>
              <p
                onClick={textField}
                className="text-right px-2 cursor-pointer text-white"
              >
                + Add New Row
              </p>
            </div>

            <CustomButton className="!mx-auto px-4 !my-5 !p-1.5" type="submit">
              Create Variant
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddNewVariant;
