import React from "react";
import login from "../../../assets/authentication/bg.jpg";
import logo from "../../../assets/authentication/logo.png";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, createTheme, TextField, ThemeProvider } from "@mui/material";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#1345B7",
      },
    },
  });

  const initialValues = {
    email: "",
    password: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      const reqBody = {
        store_email: values.email,
      };
      axios
        .post(`https://b1.bhaaraterp.com/password-forget-email/`, {
          ...reqBody,
        })
        .then((response) => {
          toast.success(response.data.message);
          navigate("/");
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
        });
    },
  });

  // const formik = useFormik({
  //   initialValues: initialValues,
  //   // validationSchema: logInSchema,
  //   onSubmit: (values, action) => {
  //     const reqBody = {
  //       email: values.email,
  //       password: values.password,
  //     };
  //     axios
  //       .post(
  //         `https://b1.bhaaraterp.com/super-admin-login/`,
  //         { ...reqBody },
  //         {
  //           headers: {
  //             Authorization: localStorage.getItem("token"),
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log("data", response.data);
  //         localStorage.setItem("token", response?.data.token);
  //         localStorage.setItem("user_name", response?.data.user_name);
  //         navigate("/dashboard");
  //         alert(response.data.message);
  //       })
  //       .catch((error) => {
  //         console.log("", error.response);
  //         console.log(error);
  //         alert(error.message);
  //       });
  //   },
  // });

  return (
    <>
      <div className="flex">
        <img
          src={login}
          alt=""
          className="h-screen fixed object-cover w-screen"
        />
        <div className="flex z-[999] flex-col h-screen justify-center items-center p-16 text-black w-[30%] border-r-2 border-opacity-20 bg-white bg-opacity-10 backdrop-blur-md">
          <img src={logo} alt="" className="h-28" />
          <div>
            <p className="text-2xl mt-10 text-left">Reset Password</p>
            <p className="my-2 text-xs">
              Enter your email address and we’ll send you an email with
              instructions to reset your password
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col w-full my-2"
          >
            <ThemeProvider theme={lightTheme}>
              <label className="py-1">Email</label>
              <TextField
                id="email"
                name="email"
                size="small"
                placeholder="Email"
                value={formik.values["email"]}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                // onError={formik.errors && formik.touched ? true : false}
                type="email"
                className="rounded-md text-black px-2 p-2 focus:!outline-none !border-2 bg-white bg-opacity-20 backdrop-blur-sm"
              />

              <div className="flex mt-5">
                <Button
                  disableElevation
                  variant="contained"
                  type={"submit"}
                  className="px-10 !rounded-md !p-1.5 !capitalize !mx-0"
                >
                  Sent 
                </Button>
              </div>
            </ThemeProvider>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
