import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function News() {
    const [data,setData]=useState([])
    const getNews = () => {
       
        axios
          .get("https://m1blog.aaragroups.com/blog/api/news_list_api/", {
            headers: {
              token: "6030357fc8126488bf023e910c0dbe35026608c4",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((res) => {
            console.log("RESPONSE ==== : ", res.data?.blog_list);
            setData(res.data?.data);
          })
          .catch((err) => {
            console.log("ERROR: ====", err);
            alert(err.response.data.message);
          });
      };

      useEffect(()=>{
        getNews();
      })
  return (
    <>
    
         {
            data?.map((item)=>{
                return <div className=" flex justify-between border border-gray-300 drop-shadow-2xl mx-auto rounded w-[98%] my-3">
                <div className="flex flex-col justify-center items-center">
                  <p className="text-2xl text-black px-4 py-4">{item.Title}</p>
                  <p className="px-4 py-2">{item.meta_description}</p>
                </div>
                <div className="w-full flex flex-col justify-center items-center">
                  <img src={item.images} alt="ab" className="h-40 w-40" />
                </div>
               
              </div>
            })
         }
       
    </>
  );
}
