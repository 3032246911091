import { Autocomplete, Box, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Test = () => {
    const [store, setStore] = useState([])
    const [storeId, setStoreId] = useState([])

    const StoreList = () => {
        axios
            .get(`https://b1.bhaaraterp.com/store-data-list/`, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            })
            .then((response) => {
                setStore(response?.data.store_list_data);
                // toast.success(response.data.message);
            })
            .catch((error) => {
                console.log("", error.response);
                console.log(error);
            });
    };
    useEffect(() => {
        StoreList()
    }, [])

    const options =
        store?.map((category) => {
            return { store_id: category.id, store_name: category.store_name };
        })

    console.log(storeId);
    return (
        <div className='flex justify-center items-center h-screen'>
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option?.store_name}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} onClick={() => setStoreId(option.store_id)}>
                        {option.store_name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}

                        label="Choose a country"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
    )
}

export default Test
