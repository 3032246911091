import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import classNames from "classnames";

const Search = ({ className }) => {
  return (
    <>
        <div className="relative ">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <SearchIcon className="text-secondary" />
          </span>
          <input
            name="Search"
            placeholder="Search"
            className={classNames(
              "!w-96 py-1.5 pl-10 text-secondary text-sm rounded-md sm:w-auto focus:outline-none border-2 border-zinc-700 bg-white focus:border-zinc-600",
              className
            )}
          />
        </div>
    </>
  );
};

export default Search;
