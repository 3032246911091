import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { CustomButton } from "../../../shared/CustomButton";

const AddBussinessCategory = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const initialValues = {
    name: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        category_name: values.category_name,
      };

      axios
        .post(
          `https://b1.bhaaraterp.com/business-category-add/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          toast.success(response.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          // alert(error.message);
        });
      action.resetForm();
    },
  });
  return (
    <>
      <CustomButton onClick={handleOpen}>+ Add Category</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 text-white bg-popup outline-none -translate-x-1/2 -translate-y-1/2 w-1/3 shadow-md p-6 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col text-black mx-auto gap-2"
          >
            <p className="text-2xl underline font-semibold text-white text-center my-5">
              Add New Category
            </p>
            <p className="text-left text-white">Category Name</p>
            <input
              autoComplete="off"
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="category_name"
              id="category_name"
              value={formik.values["category_name"]}
              onChange={formik.handleChange}
              placeholder="Enter Business Category Name"
            />
            <CustomButton className="!mx-auto px-4 !my-7 !p-2" type="submit">
              Add Category
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddBussinessCategory;
