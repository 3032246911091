import { FilterAlt } from "@mui/icons-material";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Active, Inactive } from "../../../shared/ActivaInactive";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import Search from "../../../shared/Search";
import CreateResellers from "../CreateResellers";



const Resellers = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [data, setData] = useState([]);

  console.log(page)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChange = (event, email) => {
    axios
      .post(
        `https://b1.bhaaraterp.com/reseller_app/activate-or-deactivate/`,
        {
          email: email,
          active_status: event.target.checked ? "true" : "false",
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        // setData(response.data.data);
        toast.success(response.data.message);
        ResellersListData();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  
  const ResellersListData = () => {
    axios
      .get(`https://b1.bhaaraterp.com/reseller_app/reseller-list/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log("", error);
      });
  };
  useEffect(() => {
    ResellersListData();
  }, []);

  return (
    <>
      <div className="">
        <div className="flex justify-between">
          <div className="p-2">
            <p className="text-xl text-black font-bold">Resellers List</p>
            <p className="text-black">View/Search Resellers List</p>
          </div>
          <div className="p-2 pt-5">
            <CreateResellers />
          </div>
        </div>
        <div className="flex flex-col !w-[77.5vw] !rounded overflow-auto bg-secondary border-secondary shadow-card shadow-zinc-600 m-2"  >
          <div className="flex items-center !bg-gray-100 py-2">
            <CustomIconButton>
              <FilterAlt className="text-black" />
            </CustomIconButton>
            <Search />
          </div>
          <TableContainer component={Paper} sx={{ width: '100%',maxHeight: 440, overflow: 'scroll'}}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {/* <TableCell className="!text-left !p-2">
                    <Checkbox className="!m-0" />
                  </TableCell> */}
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    <div className="!w-24">SERIAL NO.</div>
                  </TableCell>
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    <div className="!w-28">RESELLER ID  </div>
                    
                  </TableCell>
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    <div className="!w-36">NAME</div>
                  </TableCell>
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    EMAIL
                  </TableCell>
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    <div className="w-36">CONTACT NUMBER</div>
                  </TableCell>
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    CITY
                  </TableCell>
                  <TableCell className="!text-center !font-bold" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                    STATUS
                  </TableCell>
                  <TableCell className="!text-right !font-bold">
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={row.id}>
                      {/* <TableCell
                        className="!text-left !p-2"
                        component="th"
                        scope="row"
                      >
                        <Checkbox className="!m-0" />
                      </TableCell> */}
                      <TableCell className="!text-center" style={{borderRight:"1px solid #D0D0D0"}}>
                        {index + 1}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>
                        {row?.reseller_id ? row?.reseller_id : "--"}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]" style={{borderRight:"1px solid #D0D0D0",fontFamily: "'Poppins', sans-serif"}}>{row.name}</TableCell>
                      <TableCell className="!text-center !text-[12px]" style={{borderRight:"1px solid #D0D0D0" ,fontFamily: "'Poppins', sans-serif"}}>
                        {row.email}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]" style={{borderRight:"1px solid #D0D0D0" ,fontFamily: "'Poppins', sans-serif"}}>
                        {row.mobile_number ? row.mobile_number : "--"}
                      </TableCell>
                      <TableCell className="!text-center !text-[12px]" style={{borderRight:"1px solid #D0D0D0" ,fontFamily: "'Poppins', sans-serif"}}>
                        {row.city ? row.city : "--"}
                      </TableCell>
                      <TableCell className="" style={{borderRight:"1px solid #D0D0D0" ,fontFamily: "'Poppins', sans-serif"}}>
                        <span className="flex justify-center !text-[12px] items-center">
                          {row.active_status ? <Active size="small" /> : <Inactive />}
                        </span>
                      </TableCell>
                      <TableCell className="!text-right !p-2" style={{borderRight:"1px solid #D0D0D0" ,fontFamily: "'Poppins', sans-serif"}}>
                        <input
                          type="checkbox"
                          checked={row.active_status}
                          onChange={(event) => handleChange(event, row.email)}
                          className="toggle checked:!border-green-500 !border-red-500 toggle-success"
                          
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {data?.length !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[7, 14, 21, 28]}
                count={data?.length}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default Resellers;
