import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { CustomButton } from "../../../shared/CustomButton";

const CreateResellers = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
  };

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile_number: values.mobile_number,
      };

      axios
        .post(
          `https://b1.bhaaraterp.com/reseller_app/create-reseller/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          toast.success(response.data.message);
          handleClose();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          // alert(error.message);
        });
      //   action.resetForm();
    },
  });
  return (
    <>
      <CustomButton onClick={handleOpen}>+ Create Resellers</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 text-white bg-popup outline-none -translate-x-1/2 -translate-y-1/2 w-1/3 shadow-md p-6 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col text-black mx-auto gap-1"
          >
            <p className="text-2xl underline font-semibold text-white text-center my-5">
              Create Resellers
            </p>
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col">
                <p className="text-left text-white">First Name</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-zinc-700"
                  name="first_name"
                  id="first_name"
                  value={formik.values["first_name"]}
                  onChange={formik.handleChange}
                  placeholder="Enter First Name"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left text-white">Last Name</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 focus:outline-none border-2 bg-white border-zinc-700"
                  name="last_name"
                  id="last_name"
                  value={formik.values["last_name"]}
                  onChange={formik.handleChange}
                  placeholder="Enter Last Name"
                />
              </div>
            </div>

            <p className="text-left text-white">Email</p>
            <input
              autoComplete="off"
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="email"
              id="email"
              value={formik.values["email"]}
              onChange={formik.handleChange}
              placeholder="Enter Email"
            />

            <p className="text-left text-white">Contact Number</p>
            <input
              autoComplete="off"
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="mobile_number"
              id="mobile_number"
              value={formik.values["mobile_number"]}
              onChange={formik.handleChange}
              placeholder="Enter Contact Number"
            />

            <CustomButton className="!mx-auto px-4 !my-7 !p-2" type="submit">
              Create Resellers
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateResellers;
