import { FilterAlt } from "@mui/icons-material";
import axios from "axios";
import excel from "../../assets/excel.svg";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { CustomIconButton } from "../../shared/CustomIconButton";
import Search from "../../shared/Search";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { toast } from "react-toastify";

const CustomerData = () => {
  const [data, setData] = useState();

  const tableRef = useRef(null);
  const CustomerDataFileList = () => {
    axios
      .get(`http://45.64.156.214:9897/customr-export-to-excel/`)
      .then((response) => {
        setData(response?.data.data);
        // toast(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        toast(error.message);
      });
  };

  useEffect(() => {
    CustomerDataFileList();
  }, []);


  return (
    <div className="m-4">
      <div className="flex flex-col !my-3 !rounded  bg-secondary border-secondary shadow-card shadow-zinc-600">
        <div className="flex justify-between items-center p-2 h-14 border-zinc-600">
          <div className="flex items-center">
            <CustomIconButton>
              <FilterAlt />
            </CustomIconButton>
            <Search />
          </div>
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <div className="!bg-white !rounded">
              <img src={excel} alt="" className="h-10" />
            </div>
          </DownloadTableExcel>
        </div>
      </div>
      <TableContainer component={Paper} sx={{ maxWidth: 1420 }}>
        <Table className="overflow-auto !whitespace-nowrap" ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Store</TableCell>
              <TableCell align="center">Created By</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Middle Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Father Name</TableCell>
              <TableCell align="center">Nick Name</TableCell>
              <TableCell align="center">Address 1</TableCell>
              <TableCell align="center">Address 2</TableCell>
              <TableCell align="center">Area</TableCell>
              <TableCell align="center">City</TableCell>
              <TableCell align="center">State</TableCell>
              <TableCell align="center">Pincode</TableCell>
              <TableCell align="center">Nationality </TableCell>
              <TableCell align="center">Mobile No</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Total Purchase</TableCell>
              <TableCell align="center">Customer Type</TableCell>
              <TableCell align="center">Firm Status</TableCell>
              <TableCell align="center">Company Name</TableCell>
              <TableCell align="center">Last Purchase Date</TableCell>
              <TableCell align="center">Business Type</TableCell>
              <TableCell align="center">Latitude</TableCell>
              <TableCell align="center">Logitude</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item) => (
                <TableRow>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="center">{item.store}</TableCell>
                  <TableCell align="center">{item.created_by}</TableCell>
                  <TableCell align="center">{item.first_name}</TableCell>
                  <TableCell align="center">{item.middle_name}</TableCell>
                  <TableCell align="center">{item.last_name}</TableCell>
                  <TableCell align="center">{item.father_name}</TableCell>
                  <TableCell align="center">{item.nick_name}</TableCell>
                  <TableCell align="center">{item.address1}</TableCell>
                  <TableCell align="center">{item.address2}</TableCell>
                  <TableCell align="center">{item.area}</TableCell>
                  <TableCell align="center">{item.city}</TableCell>
                  <TableCell align="center">{item.state}</TableCell>
                  <TableCell align="center">{item.pincode}</TableCell>
                  <TableCell align="center">{item.nationality}</TableCell>
                  <TableCell align="center">{item.mobile_no}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.total_purchase}</TableCell>
                  <TableCell align="center">{item.customer_type}</TableCell>
                  <TableCell align="center">{item.firm_status}</TableCell>
                  <TableCell align="center">{item.company_name}</TableCell>
                  <TableCell align="center">
                    {item.last_purchase_date}
                  </TableCell>
                  <TableCell align="center">{item.business_type}</TableCell>
                  <TableCell align="center">{item.latitude}</TableCell>
                  <TableCell align="center">{item.longitude}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomerData;
