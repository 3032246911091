import { Box, Modal } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../../shared/CustomButton";

const AddSubCategory = () => {
  const [businessCategory, setBusinessCategory] = useState();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BusinessCategoryList = () => {
    axios
      .post(
        `https://b1.bhaaraterp.com/business-category-list-data/`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setBusinessCategory(response?.data.business_category_list_data);
        // alert(response.data.message);
      })
      .catch((error) => {
        console.log("", error.response);
        console.log(error);
        // alert(error.message);
      });
  };

  useEffect(() => {
    BusinessCategoryList();
  },[]);

  const initialValues = {
    business_category_id: "",
    sub_category_name: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        business_category_id: values.business_category_id,
        sub_category_name: values.sub_category_name,
      };

      axios
        .post(
          `https://b1.bhaaraterp.com/add-sub-category-data/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          // alert(response.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          // alert(error.message);
        });
      action.resetForm();
    },
  });
  return (
    <>
      <CustomButton onClick={handleOpen}>+ Add Sub-Category</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 text-white bg-popup outline-none -translate-x-1/2 -translate-y-1/2 w-1/3 shadow-md p-6 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col text-black mx-auto gap-2"
          >
            <p className="text-2xl underline font-semibold text-white text-center my-5">
              Add Business Sub Category
            </p>
            <p className="text-left text-white">Business Category Name</p>
            <select
              className="rounded-md text-black p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="business_category_id"
              id="business_category_id"
              value={formik.values["business_category_id"]}
              onChange={formik.handleChange}
            >
              <option value="">Select Category</option>;
              {businessCategory &&
                businessCategory.map((category) => {
                  return <option key={category.id} value={category.id}>{category.name}</option>;
                })}
            </select>

            <p className="text-left text-white">Business Sub Category Name</p>
            <input
              autoComplete="off"
              className="rounded-md text-black p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="sub_category_name"
              id="sub_category_name"
              value={formik.values["sub_category_name"]}
              onChange={formik.handleChange}
              placeholder="Enter Business Sub Category Name"
            />
            <CustomButton className="!mx-auto !px-4 !my-5 !p-1.5" type="submit">
              Add Sub Category
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddSubCategory;
