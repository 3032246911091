import { Switch } from "@mui/material";
import classNames from "classnames";
import React from "react";

const CustomToggleButton = ({
  name,
  id,
  value,
  onChange,
  className,
  checked,
}) => {
  return (
    <>
      <Switch
        color="success"
        name={name}
        id={id}
        checked={checked}
        value={value}
        onChange={onChange}
        className={classNames(className)}
      />
    </>
  );
};

export default CustomToggleButton;
