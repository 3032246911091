import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import { useFormik } from "formik";
import axios from "../../../config/axios";
import { CustomButton } from "../../../shared/CustomButton";

const CreateNewCombo = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const initialValues = {
    variant_name: "",
    variant_values: "",
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqBody = {
        varient_name: values.variant_name,
        varient_values: values.variant_values,
      };

      axios
        .post(
          `https://b1.bhaaraterp.com/add-varient-value-data/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          // setData(response.data);
          // alert(response.data.message);
          handleClose();
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          // alert(error.message);
        });
      action.resetForm();
    },
  });

  return (
    <>
      <CustomButton onClick={handleOpen}>+ Create New Combo</CustomButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="absolute top-1/2 left-1/2 text-white bg-popup outline-none -translate-x-1/2 -translate-y-1/2 w-2/3 shadow-md p-6 rounded">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col text-black mx-auto gap-2"
          >
            <p className="text-2xl underline font-semibold text-white text-center my-5">
              Create New Combinations
            </p>
            <p className="text-left my-2 text-white">Group Name</p>
            <input
              autoComplete="off"
              className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
              name="group_id"
              id="group_id"
              value={formik.values["group_id"]}
              onChange={formik.handleChange}
              placeholder="Ex: Color & Size"
            />

            <div className="grid grid-cols-2 gap-5 justify-center items-center">
              <div className="flex flex-col">
                <p className="text-left my-2 text-white">Attribute1</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                  name="attribute1"
                  id="attribute1"
                  value={formik.values["attribute1"]}
                  onChange={formik.handleChange}
                  placeholder="Ex: Color"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left my-2 text-white">Value</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                  name="group_value1"
                  id="group_value1"
                  value={formik.values["group_value1"]}
                  onChange={formik.handleChange}
                  placeholder="Ex: Red"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left my-2 text-white">Attribute2</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                  name="attribute2"
                  id="attribute2"
                  value={formik.values["attribute2"]}
                  onChange={formik.handleChange}
                  placeholder="Ex: Size"
                />
              </div>
              <div className="flex flex-col">
                <p className="text-left my-2 text-white">Value</p>
                <input
                  autoComplete="off"
                  className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                  name="group_value2"
                  id="group_value2"
                  value={formik.values["group_value2"]}
                  onChange={formik.handleChange}
                  placeholder="Ex: medium"
                />
              </div>
            </div>

            <div className="flex flex-col my-2 w-full">
              <p className="text-left my-2 text-white">Discription</p>
              <textarea
                autoComplete="off"
                rows="3"
                className="rounded-md p-2 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                name="discription"
                id="discription"
                value={formik.values["discription"]}
                onChange={formik.handleChange}
                placeholder="Ex: Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              />
            </div>

            <CustomButton className="!mx-auto px-4 !my-5 !p-1.5" type="submit">
              + Create Variant Combo
            </CustomButton>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateNewCombo;
