import React, { useState } from "react";
import { Switch } from "@mui/material";
import { useFormik } from "formik";
import axios from "../../../config/axios";
import { useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Search from "../../../shared/Search";
import { CustomIconButton } from "../../../shared/CustomIconButton";
import { CustomButton } from "../../../shared/CustomButton";
import { toast } from "react-toastify";
import { permissionData } from "../../../Mock";

const StorePermission = () => {
  const [data, setData] = useState();
  const [data1, setData1] = useState();

  const id = window.location.pathname.split("/")[2];

  const PermissionData = async () => {
    try {
      const response = await axios.get(
        `/store-data-by-id/?store_id=${id}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setData1(response?.data?.store_list_data[0]);
      setData(
        response?.data?.store_list_data[0] &&
          response?.data?.store_list_data[0].store_permission_data[0]
      );
      // toast.success(response.data.message);
    } catch (error) {
      toast(error.message);
    }
  };
  useEffect(
    () => {
      PermissionData();
    }, // eslint-disable-next-line
    []
  );

  const initialValues = {
    store_id: data ? data && data.store_id : id,
    sales_update: data ? data && data.sales : false,
    erp_update: data ? data && data.erp : false,
    hrm_update: data ? data && data.hrm : false,
    api_update: data ? data && data.api : false,
    purchase_update: data ? data && data.purchase : false,
    leads_update: data ? data && data.leads : false,
    ledger_update: data ? data && data.ledger : false,
    report_update: data ? data && data.report : false,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    onSubmit: (values) => {
      const reqBody = {
        store_id: values.store_id,
        sales_update: values.sales_update,
        erp_update: values.erp_update,
        hrm_update: values.hrm_update,
        api_update: values.api_update,
        purchase_update: values.purchase_update,
        leads_update: values.leads_update,
        ledger_update: values.ledger_update,
        report_update: values.report_update,
      };

      axios
        .post(
          `https://b1.bhaaraterp.com/store-data-update/`,
          { ...reqBody },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          PermissionData();
          toast.success("Permission Updated Successfully");
        })
        .catch((error) => {
          console.log("", error.response);
          console.log(error);
          toast.error(error.message);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="my-3 shadow-md">
        <div className="flex justify-between items-center ml-2">
          <p className="p-2 border-2 border-zinc-700 rounded-md">
            {data1 && data1.store_name}
          </p>
          <CustomButton type="submit">Update Info</CustomButton>
        </div>
        <div className="">
          <div className="flex flex-col !my-3 !rounded bg-secondary border-secondary shadow-card shadow-zinc-600 m-2">
            <div className="flex items-center p-2 h-14 border-zinc-600">
              <CustomIconButton>
                <FilterAltIcon />
              </CustomIconButton>
              <Search />
            </div>
            <div className="flex justify-between items-center border-zinc-600 bg-secondary border-b-2">
              <p className="flex w-full p-3">Permission Name</p>
              <div className="flex justify-center w-full mx-auto">
                <p>Action</p>
              </div>
            </div>
            {permissionData.map((checkbox) => {
              return (
                <div
                  key={checkbox.id}
                  className="flex justify-between items-center border-zinc-600  border-b"
                >
                  <p className="w-full p-3">{checkbox.item}</p>
                  <div className="flex justify-center w-full">
                    <Switch
                      color="warning"
                      id={checkbox.id}
                      name={checkbox.id}
                      onChange={formik.handleChange}
                      checked={formik.values[checkbox.id]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </>
  );
};

export default StorePermission;
