import React, { useRef, useState } from "react";
import {
  Checkbox,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { FilterAlt } from "@mui/icons-material";
import { useEffect } from "react";
import { CustomIconButton } from "../../shared/CustomIconButton";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Switch from "@mui/material/Switch";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Popup from "reactjs-popup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";

// import List from "@mui/joy/List";
// import ListItemDecorator from "@mui/joy/ListItemDecorator";
// import ListItemButton from "@mui/joy/ListItemButton";
// import Videocam from "@mui/icons-material/Videocam";
// import Image from "@mui/icons-material/Image";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  rounded: 2,
};

const options2 = [
  { value: "Pending", label: "Pending" },
  { value: "Accepted", label: "Accepted" },
  { value: "Assigned", label: "Assigned" },
  { value: "Shipped", label: "Shipped" },
  { value: "Rejected", label: "Rejected" },
  { value: "Delivered", label: "Delivered" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Refunded", label: "Refunded" },
  { value: "Returned", label: "Returned" },
  { value: "Replacement", label: "Replacement" },
];

const OrderManagement = () => {
  const { state } = useLocation();
  const [selectedStatus, setSelectedStatus] = useState(options2[0]);
  const [orderData, setOrderData] = useState([]);
  const [store, setStore] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDates, setToDates] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const hideBox = useRef();
  const box1 = useRef();
  const [updatedata, setUpdatedata] = useState([]);
  const [updateStatus, setUpdatedStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  console.log("location data==>", state?.status);

  // const filteredOptions = options2.filter((option) => {
  //   if (selectedStatus.value === "Pending") {
  //     return option.value === "Accepted" || option.value === "Rejected";
  //   } else if (selectedStatus.value === "Accepted") {
  //     return option.value === "Accepted" || option.value === "Assigned";
  //   } else if (selectedStatus.value === "Assigned") {
  //     return option.value === "Assigned" || option.value === "Shipped";
  //   } else if (selectedStatus.value === "Shipped") {
  //     return option.value === "Shipped" || option.value === "Delivered";
  //   } else if (selectedStatus.value === "Delivered") {
  //     return (
  //       option.value === "Returned" ||
  //       option.value === "Refunded" ||
  //       option.value == "Rejected"
  //     );
  //   } else if (
  //     selectedStatus.value === "Delivered" ||
  //     selectedStatus.value === "Refunded" ||
  //     selectedStatus.value === "Cancelled"
  //   ) {
  //     return null;
  //   }
  // });

  const handleUpdate = (value, id) => {
    // console.log("statusData==>", selectedStatus);
    // const fd = new FormData();
    const sendStatus = {
      order_id: id,
      change_order_status: value,
    };

    // fd.append();
    // fd.append();

    axios
      .post("https://b1.bhaaraterp.com/sales-order-list-/", sendStatus, {
        headers: {
          // Accept: "application/json",
          // "Content-Type": "multipart/formdata",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // toast.success("Status Update Successfully");
        setUpdatedata(res.data);
        OrderManagement();
        console.log("status change");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //
  // sales-order-assign-order-to-another-store/

  const [checked, setChecked] = React.useState(false);

  const handleChange = (id) => {
    console.log(id, "ID");
    axios
      .post(
        `https://b1.bhaaraterp.com/sales-order-assign-order-to-another-store/`,
        {
          order_id: id,
          assign_order_to_another_store_id: 98,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log("orderManagementApi===>", response.data.data);
        setOrderData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("check status===>", checked);

  // const [rowsPerPage, setRowsPerPage] = useState(7);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(null);

  const PreviousData = () => {
    setPage(page - 1);
  };
  const nextData = () => {
    setPage(page + 1);
  };
  console.log(page);

  console.log(checked, "Checked");

  const StoreList = () => {
    axios
      .get(`https://b1.bhaaraterp.com/store-data-list/`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setStore(response?.data.store_list_data);
        // toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const options = store?.map((category) => {
    return { store_id: category.id, store_name: category.store_name };
  });
  
  useEffect(() => {
    StoreList();
  }, []);
  const OrderListData = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/sales-order-list-/?store_id=${storeId}&from_date=${fromDate}&to_date=${toDates}&order_status=${
          state?.status ? state?.status : selectStatus
        }&page=${page}&sales_order_id=${searchQuery}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log("orderManagementApi===>", response?.data?.count);
        setOrderData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        console.log("error why==>");
      });
  };
  useEffect(() => {
    OrderListData();
  }, [storeId, fromDate, toDates, selectStatus, page, updatedata, searchQuery]);

  const handleStore = (storeId) => {
    setStoreId(storeId);
    setOpen(false);
  };

  const handleShow = () => {
    hideBox.current.style.display = "none";
  };

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const closeModal1 = () => setOpen1(false);

  const closeModal = () => {
    box1.current.style.display = "none";
  };

  const arr1 = ["Pending", "Accepted", "Rejected"];

  console.log("order status===>", updateStatus);

  return (
    <div className="!float-right px-2" style={{ width: "100%" }}>
      <div className="flex justify-between">
        <div className="p-2">
          <p className="text-xl !text-black font-bold">Order Management List</p>
          <p className="!text-black">View/Search Order Management List</p>
        </div>
        <div className="p-2 pt-5">{/* <CreateResellers /> */}</div>
      </div>
      <div
        className="flex flex-col !rounded !text-black bg-slate-100 shadow-card shadow-zinc-600"
        style={{ width: "100%" }}
      >
        <div className="flex items-center justify-between my-2">
          <span className="flex  gap-3">
            <CustomIconButton>
              <FilterAlt
                onClick={handleOpen}
                className="!text-sky-900 text-transparent !bg-white"
              />
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className=""
              >
                <Box
                  sx={style}
                  className="!bg-slate-100 !rounded-2xl !w-96"
                  ref={hideBox}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div className="text-center font-bold underline underline-offset-1">
                      Filter{" "}
                    </div>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {/* <DatePicker label="From Date" value={fromDate} onChange={(event) => setFromDate(event.target.value)} className='!w-[100%]' /><br/> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker label="Basic date picker" />
                      </DemoContainer>
                    </LocalizationProvider> */}
                    FROM DATE :
                    <input
                      type="date"
                      className="w-[100%] rounded h-10 px-2"
                      style={{ border: "1px solid gray" }}
                    />
                    <br />
                    <div className="pt-3">TO DATE :</div>
                    <input
                      type="date"
                      value={toDates}
                      onChange={(event) => setToDates(event.target.value)}
                      className="border border-black w-[100%] h-10 rounded px-2 ms-6"
                    />
                    <br />
                    {/* Order Status <input type='text' placeholder='E' className='border border-black w-[60%] rounded-sm my-3' /><br /> */}
                    <div className="pt-3">STATUS :</div>
                    <select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="!h-10 !text-black !border border-black rounded !w-[100%]"
                      value={selectStatus}
                      onChange={(event) => setSelectStatus(event.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Accepted">Accepted</option>
                      <option value="Assigned">Assigned</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Refunded">Refunded</option>
                      <option value="Returned">Returned</option>
                    </select>
                    <br />
                    <div className="pt-3">STORE :</div>
                    {/* <Autocomplete
                          options={options}
                          getOptionLabel={(option) => option?.store_name}
                          className="!w-[100%] !border-black !h-10 !border-2 !text-black !rounded"
                          renderOption={(props, option) => (
                            <MenuItem
                              {...props}
                              onClick={() => handleStore(option.store_id)}
                            >
                              {option.store_name}
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              className="!border-black !h-10 !text-black !border-2 !rounded-xl"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        /> */}
                    <select
                      className="!h-10 !text-black !border border-black rounded !w-[100%]"
                      value={storeId}
                      onChange={(e) => setStoreId(e.target.value)}
                    >
                      {store.map((item) => (
                        // console.log("store data==>",item.id)
                        <option value={item.id}>{item.store_name}</option>
                      ))}
                    </select>
                    <br />
                    <div className="justify-between items-center py-4 gap-x-5">
                      <Button
                        variant="contained"
                        color="warning"
                        className="!bg-primary-700 !text-black"
                        onClick={handleClose}
                      >
                        CANCEL
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleClose}
                        className="float-right "
                      >
                        Apply
                      </Button>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </CustomIconButton>
            <input
              type="text"
              value={searchQuery}
              className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
              placeholder="search"
              style={{ border: "1px solid black" }}
              onChange={(event) => setSearchQuery(event.target.value)}
            />
          </span>
          <span>
            <Autocomplete
              options={options}
              getOptionLabel={(option) => option?.store_name}
              className="!w-72 !mr-3"
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  onClick={() => handleStore(option.store_id)}
                >
                  {option.store_name}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="choose a store"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </span>
        </div>

        <TableContainer
          component={Paper}
          // sx={{  }}
          sx={{ width: "100%", maxHeight: 440 }}
          className="!text-black !mx-auto !bg-white overflow-auto px-2"
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {/* <TableCell className="!text-left !p-2">
                  <Checkbox className="!m-0 !text-black" />
                </TableCell> */}
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <div className="!w-24">ORDER DATE</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <div className="!w-24">ORDER ID</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <div className="!w-32"> STORE NAME</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <div className="!w-60"> CUSTOMER NAME</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  MOBILE
                </TableCell>

                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <div className="!w-24">AMOUNT</div>
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  <div className="!w-32">STATUS</div>
                  
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center !text-gray-800 !font-bold"
                >
                  CHANGE STATUS
                </TableCell>
                <TableCell
                  style={{
                    borderRight: "1px solid #D0D0D0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                  className="!text-center  !text-gray-800 !font-bold"
                >
                  <div className="!w-40">ASSIGN TO ANOTHER</div>
                </TableCell>
                <TableCell
                  className="!text-center !w-44 !text-gray-800 !font-bold"
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                >
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            {/* <div className=" !overflow-y-auto w-[100%]" style={{height:"100vh"}}> */}
            <TableBody className="!max-h-[50vh] !overflow-y-auto">
              {orderData &&
                orderData?.map((row) => (
                  <TableRow className="">
                    {/* <TableCell
                    className="!text-left !p-2 !text-black"
                    component="th"
                    scope="row"
                  >
                    <Checkbox className="!m-0 !text-slate-700" />
                  </TableCell> */}
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.sales_order_date
                        ? row.sales_order_date.slice(1, 10)
                        : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.sales_order ? row.sales_order : "--"}
                    </TableCell>
                    <TableCell  
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.store?.store_name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px] !py-[-20px] !my-[-20px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.customer_name?.first_name}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.customer_name?.mobile_no}
                    </TableCell>

                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {row?.total_amount ? row.total_amount : "--"}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-[12px]"
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      <span>
                        {row?.status == "Delivered" ? (
                          <Button
                            className="!bg-green-400 !text-[8px] !text-white"
                            size="small"
                          >
                            Delivered
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Pending" ? (
                          <Button
                            className="!bg-pink-500 !text-[8px] !text-white"
                            size="small"
                          >
                            Pending
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Order Placed" ? (
                          <Button
                            className="!bg-yellow-400 !text-[8px] !text-white"
                            size="small"
                          >
                            Order Placed
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Shipped" ? (
                          <Button
                            className="!bg-purple-400 !text-[8px] !text-white"
                            size="small"
                          >
                            Shipped
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Drafts" ? (
                          <Button
                            className="!bg-red-600 !text-[8px] !text-white"
                            size="small"
                          >
                            Drafts
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Accepted" ? (
                          <Button
                            className="!bg-sky-300 !text-[8px] !text-white"
                            size="small"
                          >
                            Accepted
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Rejected" ? (
                          <Button
                            className="!bg-red-700 !text-[8px] !text-white"
                            size="small"
                          >
                            Rejected
                          </Button>
                        ) : null}
                      </span>
                      <span>
                        {row?.status == "Assigned" ? (
                          <Button className="!bg-fuchsia-500 !text-[8px] !text-white">
                            Assigned
                          </Button>
                        ) : null}
                      </span>
                      {/* <span>{row?.status}</span> */}
                    </TableCell>
                    <TableCell
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "font-family: 'Poppins', sans-serif;",
                      }}
                    >
                      {row?.status == "Delivered" ? null : (
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 120 }}
                          size="small"
                        >
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={updateStatus}
                            className="!h-6 !py-0 !my-0"
                            onChange={(event) =>
                              handleUpdate(event.target.value, row.id)
                            }
                          >
                            {row?.status === "Pending" &&
                              arr1.map((items) => (
                                <MenuItem value={items} className="">{items}</MenuItem>
                              ))}
                            {row?.status === "Accepted" &&
                              ["Accepted", "Assigned"].map((items) => (
                                <MenuItem value={items}>{items}</MenuItem>
                              ))}
                            {row?.status === "Assigned" &&
                              ["Assigned", "Shipped", "Cancelled"].map(
                                (items) => (
                                  <MenuItem value={items}>{items}</MenuItem>
                                )
                              )}
                            {row?.status === "Shipped" &&
                              ["Shipped", "Delivered"].map((items) => (
                                <MenuItem value={items}>{items}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </TableCell>
                    <TableCell
                      className="!text-center !text-blue-700 "
                      style={{
                        borderRight: "1px solid #D0D0D0",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      {/* {
                      row?.transfer_order_to_store_id ? row?.transfer_order_to_store_id :"--"
                    } */}

                      <Switch
                        onChange={() => handleChange(row?.id)}
                        className="!text-center !text-blue-700"
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      className="!text-center "
                      style={{ fontFamily: "'Poppins', sans-serif" }}
                    >
                      <Popup
                        trigger={
                          <button className="button text-white rounded">
                            {/* <RemoveRedEyeIcon
                                    className="!text-blue-500"
                                    
                                  /> */}
                            <Button
                              onClick={handleOpen1}
                              className="!bg-blue-500 !text-[8px] !text-white"
                              // size="small"
                              style={{ fontFamily: "'Poppins', sans-serif" }}
                            >
                              view
                            </Button>
                          </button>
                        }
                        modal
                      >
                        <div
                          className=" text-black rounded-xl p-4"
                          style={{
                            border: "1px solid gray",
                            background:
                              "linear-gradient(180deg, rgba(2, 0, 40, 1) 0%, rgba(1, 0, 75, 1) 49%, rgba(2, 0, 95, 1) 65%, rgba(2, 0, 120, 1) 100%)",
                          }}
                          ref={box1}
                        >
                          <div
                            className="absolute cursor-pointer right-[-9px] top-[-7px] rounded"
                            onClick={closeModal}
                          >
                            <span className="text-white bg-black rounded-2xl pb-2 p-1">
                              <CloseIcon />
                            </span>
                          </div>
                          <div className="text-center text-lg underline text-red-800 font-bold">
                            All Details{" "}
                          </div>
                          <div className=" text-white mx-auto flex py-2">
                            <div className="ml-10">
                              <p className="w-40 text-[12px] py-1">
                                CUSTOMER NAME :{" "}
                              </p>
                              <p className="w-40 text-sm text-[12px] py-1"> ORDER DATE :</p>
                              <p className="w-40 text-sm text-[12px] py-1">ORDER ID : </p>
                              <p className="w-40 text-sm text-[12px] py-1">STORE NAME :</p>
                              <p className="w-40 text-sm text-[12px] py-1">MOBILE : </p>
                              <p className="w-40 text-sm text-[12px] py-1">AMOUNT : </p>
                              <p className="w-40 text-sm text-[12px] py-1">STATUS : </p>
                              <p className="w-40 text-sm text-[12px] py-1 pt-4">
                                TOTAL PRICE :{" "}
                              </p>
                              <p className="w-40 text-sm text-[12px] py-1">
                                SHIPPING CHARGES :
                              </p>
                              <p className="w-40 text-sm text-[12px] py-1">DISCOUNT:</p>
                              <p className="w-40 text-sm text-[12px] py-1">DUE AMOUNT:</p>
                            </div>
                            <div
                              className=""
                              style={{ borderRight: "1px solid gray" }}
                            >
                              <div className="w-48 text-sm text-[12px] py-1 ">
                                {row?.customer_name?.first_name
                                  ? row?.customer_name?.first_name.slice(1, 25)
                                  : "--"}
                              </div>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.sales_order_date
                                  ? row.sales_order_date.slice(1, 10)
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.sales_order ? row.sales_order : "--"}
                              </p>
                              <p className="w-48 text-sm  py-1">
                                {row?.store?.store_name}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.customer_name?.mobile_no}
                              </p>
                              <p className="w-48  text-sm text-[12px]  py-1">
                                {row?.total_amount ? row.total_amount : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                <span>
                                  {row?.status == "Delivered" ? (
                                    <Button
                                      size="small"
                                      className="!bg-green-400 text-sm text-[12px]  !text-white"
                                    >
                                      Delivered
                                    </Button>
                                  ) : null}
                                </span>
                                <span>
                                  {row?.status == "Pending" ? (
                                    <Button
                                      size="small"
                                      className="!bg-pink-500 text-sm text-[12px]  !text-white"
                                    >
                                      Pending
                                    </Button>
                                  ) : null}
                                </span>
                                <span>
                                  {row?.status == "Order Placed" ? (
                                    <Button
                                      size="small"
                                      className="!bg-yellow-400 text-sm text-[12px]  !text-white"
                                    >
                                      Order Placed
                                    </Button>
                                  ) : null}
                                </span>
                                <span>
                                  {row?.status == "Shipped" ? (
                                    <Button
                                      size="small"
                                      className="!bg-purple-400 text-sm text-[12px]  !text-white"
                                    >
                                      Shipped
                                    </Button>
                                  ) : null}
                                </span>
                                <span>
                                  {row?.status == "Drafts" ? (
                                    <Button
                                      size="small"
                                      className="!bg-red-600 text-sm text-[12px]  !text-white"
                                    >
                                      Drafts
                                    </Button>
                                  ) : null}
                                </span>
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.total_price}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.shipping_charges}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.discount ? row?.discount : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.due_amount ? row?.due_amount : "--"}
                              </p>
                            </div>
                            <div className="" style={{ paddingLeft: "15px" }}>
                              <p className="py-1 text-sm text-[12px]   w-40">
                                STORE ADDRESS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px]  w-40"> COUNTRY :</p>
                              <p className="py-1 text-sm text-[12px]  w-40"> EMAIL :</p>
                              <p className="py-1 text-sm text-[12px]  w-40">STATE : </p>
                              <p className="py-1 text-sm text-[12px]  w-40">CITY :</p>
                              <p className="py-1 text-sm text-[12px]  w-40">PINCODE : </p>
                              <p className="py-1 text-sm text-[12px]  w-40">BANK NAME : </p>
                              <p className="py-1 text-sm text-[12px]  w-40">ACCOUNT NO : </p>
                              <p className="py-1 text-sm text-[12px]  w-40">
                                CURRENT BALENCE :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px]  w-40">PAN NUMBER : </p>
                              <p className="py-1 text-sm text-[12px]  w-40">SIGNATURE : </p>
                              <p className="py-1 text-sm text-[12px]  w-40">SWIFT CODE : </p>
                            </div>
                            <div
                              className=""
                              style={{ borderRight: "1px solid gray" }}
                            >
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.store_address
                                  ? row?.store?.store_address
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.country
                                  ? row?.store?.country
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.email ? row?.store?.email : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.state ? row?.store?.state : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.city ? row?.store?.city : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.pincode
                                  ? row?.store?.pincode
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px]  py-1">
                                {row?.store?.bank_name
                                  ? row?.store?.bank_name
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.store?.account_no
                                  ? row?.store?.account_no
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.store?.current_balance
                                  ? row?.store?.current_balance
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.store?.pan_number
                                  ? row?.store?.pan_number
                                  : "--"}
                              </p>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.store?.signature ? "YES" : "NO"}
                              </p>
                              <p className="w-48 text-sm text-[12px] py-1">
                                {row?.store?.swift_code
                                  ? row?.store?.swift_code
                                  : "--"}
                              </p>
                            </div>
                            <div className="" style={{ paddingLeft: "15px" }}>
                              <p className="py-1 text-sm text-[12px] w-40">
                                CUSTOMER STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                {" "}
                                DELIVERY MANAGE BY :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                MANUFACTURING STATEUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">CURRENCY : </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                REAL TIME SYNCE :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                TEAM STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                LEAD STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                {" "}
                                VARIANT STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                SPEC STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                SYNC STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-40">
                                BULK PRODUCT STATUS :{" "}
                              </p>
                              <p className="py-1 text-sm text-[12px] w-44">
                                HIRING HISTORY STATUS :{" "}
                              </p>
                            </div>
                            <div className="">
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.customer_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.delivery_manages_by
                                  ? row?.store?.delivery_manages_by
                                  : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.manufacturing_status
                                  ? row?.store?.manufacturing_status
                                  : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.currency ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.is_real_time_sync ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.team_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.leads_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.variant_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.spec_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.sync_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.bulk_product_status ? "yes" : "no"}
                              </p>
                              <p className="w-20 text-sm text-[12px] py-1">
                                {row?.store?.hiring_history_status
                                  ? "yes"
                                  : "no"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Popup>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {/* </div> */}
          </Table>
        </TableContainer>

        <div
          className="float-right py-2 gap-x-4 bg-white w-[79.15%]fixed right-2"
          style={{ borderTop: "1px solid gray" }}
        >
          <button
            onClick={PreviousData}
            className="float-right text-start relative right-8"
          >
            {" "}
            <ArrowBackIosIcon className="!text-gray-400" />
          </button>
          <button
            onClick={nextData}
            className="float-right text-end relative right-[-8px]"
          >
            <ArrowForwardIosIcon className="!text-gray-400" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderManagement;
