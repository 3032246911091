import { IconButton } from "@mui/material";
import classNames from "classnames";
import React from "react";

export const CustomIconButton = ({ onClick, type, className, children }) => {
  return (
    <>
      <IconButton
        onClick={onClick}
        type={type}
        className={classNames("!bg-white !text-secondary !rounded-md !p-1.5 !mx-2", className)}
      >
        {children}
      </IconButton>
    </>
  );
};
