import React, { useEffect, useState } from "react";
import axios from "../../config/axios";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Search from "../../shared/Search";
import { Active, Inactive, Unmaped } from "../../shared/ActivaInactive";
import AddNewVariant from "./AddNewVariant";
import { useFormik } from "formik";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomIconButton } from "../../shared/CustomIconButton";
import { CustomButton } from "../../shared/CustomButton";
import CustomToggleButton from "../../shared/CustomToggleButton";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import EditIcon from "@mui/icons-material/Edit";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getCalendarPickerSkeletonUtilityClass } from "@mui/lab";
import { InputLabel, MenuItem, Select } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// ----------------------------------Pagination start--------------------------

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});
// -------------------------Pagination end---------------------

const VariantManagement = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [category, setCategory] = useState([]);

  const [varientId, setVarientId] = useState("");
  const [value1, setValue1] = useState("");

  const navigate = useNavigate();

  // start
  const [newCategory, setNewCategory] = useState([]);

  // end

  // edit start
  const [rowData, setRowData] = useState([]);
  const [ids, setIds] = useState(null);
  const [addBtn, setAddBtn] = useState(true);

  const [categoryId, setCategoryId] = useState("");
  // edit close

  const previousData = () => {
    setPage(page - 1);
  };
  const nextData = () => {
    setPage(page + 1);
  };
  console.log(page);

  // -------------------------Pagination start---------------------
  const { items } = usePagination({
    count: data.length,
  });
  console.log("count===>", data.length + 1);
  // -------------------------Pagination end---------------------
  const VariantList = () => {
    axios
      .get(
        `https://b1.bhaaraterp.com/other-varient-value-data-list/?page=${page}&category_id&product_other_variant_name=`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
        console.log("variant management===>", response.data.data);
      })
      .catch((error) => {
        // console.log("page not found", error);
        alert(error.message);
      });
  };

  useEffect(() => {
    VariantList();
  }, [page]);

  useEffect(() => {
    axios
      .get(
        `https://b1.bhaaraterp.com/other-varient-data-list/?page=1&category_id&product_other_variant_name=`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        setCategory(response.data.data);
        console.log("varient data list===>", response.data.data);
      })
      .catch((error) => {
        console.log("page not found", error);
        // alert(error.message);
      });
  }, []);

  const createData = () => {
    const subData = {
      id: ids,
      other_variant_id: varientId,
      value: value1,
    };
    !ids
      ? axios
          .post(
            `https://b1.bhaaraterp.com/add-other-variant-value-data/`,
            subData,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            // setStore(response?.data.store_list_data);
            ToastContainer.success(response.message);
            // console.log("business store ==>", response.data.data)
            // setToggleBtn(true)
            toast(response?.message);
            setValue1("");
            setVarientId("");
          })
          .catch((error) => {
            console.log("error page", error.response);
            console.log(error);
          })
      : axios
          .post(
            `https://b1.bhaaraterp.com/other-varient-value-data-list/`,
            subData,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            // setStore(response?.data.store_list_data);
            ToastContainer.success(response.message);
            // console.log("business store ==>", response.data.data)
            // setToggleBtn(true)
            setValue1("");
            setVarientId("");
            setAddBtn(true);
          })
          .catch((error) => {
            console.log("error page", error.response);
            console.log(error);
          });
  };

  const redirect = () => {
    navigate("/variant-management-other/variant-other-list");
    // window.location.href="/variant-mangement-color/variant-list"
  };

  useEffect(() => {
    axios
      .get(
        `https://b1.bhaaraterp.com/product-category-list-data/?page=2&business_sub_category_id&product_category_name`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log("product-category-list-data===>", response?.data?.data);
        setNewCategory(response?.data?.data);
      })
      .catch((error) => {
        console.log("page not found", error);
      });
  }, []);

  useEffect(() => {
    rowData !== [] && setValue1(rowData?.value);
    setCategoryId(rowData?.id);
  }, [rowData]);

  const EditData = (row) => {
    setRowData(row);
    setIds(row.id);
    console.log(row);
    setAddBtn(false);
  };

  return (
    <>
      <div className="bg-slate-100 relative">
        <div className="flex justify-between bg-slate-100">
          <div className="p-2">
            <p className="text-xl font font-bold text-black">
              Manage Other Variant (Type Three)
            </p>
            <p className="text-black">Manage Your Variants Storage</p>
          </div>

          <div className="mt-6 mr-3">
            <Button
              onClick={handleOpen}
              className="!text-sm !font-bold !bg-white !px-2 !py-1 mt-2"
              style={{ border: "1px solid black" }}
            >
              + Add New Other Variant
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="!border-0 !rounded-xl">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <div className="!text-center underline">
                    {addBtn ? "Add Other Variant" : "Update Other Variant"}
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="gap-y-3">
                    <InputLabel id="demo-simple-select-label">Name</InputLabel>
                    <Select
                      className="!w-80 !mt-2 !text-black"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={varientId}
                      onChange={(event) => setVarientId(event.target.value)}
                    >
                      {category
                        ? category.map((item) => (
                            <MenuItem value={item.id}>
                              {item?.title?.choice}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                    <InputLabel id="outlined-basic">Other Value</InputLabel>
                    <TextField
                      value={value1}
                      onChange={(event) => setValue1(event.target.value)}
                      id="outlined-basic"
                      label="value"
                      variant="outlined"
                      className="!w-80 !mt-2"
                    />
                  </div>
                  <div className="justify-between items-center ">
                    <Button
                      variant="outlined"
                      className="float-left top-3"
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    {addBtn ? (
                      <Button
                        variant="outlined"
                        className="float-right top-3 mr-3"
                        style={{ marginRight: "15px" }}
                        onClick={createData}
                      >
                        CREATE
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        className="float-right top-3"
                        style={{ marginRight: "15px" }}
                        onClick={createData}
                      >
                        UPDATE
                      </Button>
                    )}
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>

        <div className="flex flex-col !my-3 !rounded  bg-dark bg-slate-100 shadow-card shadow-zinc-600 m-2">
          <div className="flex items-center justify-between p-2 h-14 border-zinc-600">
            <div className="flex">
              <CustomIconButton>
                <FilterAltIcon className="!text-black" />
              </CustomIconButton>
              {/* <Search /> */}
              <input
                type="text"
                className="!border-3 !border-black !w-72 px-2 rounded-md py-1"
                placeholder="search"
                style={{ border: "1px solid black" }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            {/* <CustomToggleButton
              id="activate-deactivate"
              name="activate-deactivate"

            /> */}
            <div className="flex">
              {/* <select
                className="rounded-md text-black p-1.5 sm:w-auto focus:outline-none border-2 bg-white border-zinc-700"
                name="store_id"
                id="store_id"
              >
                <option>hello</option>
                <option>hello</option>
                <option>hello</option>
              </select> */}
              <div className="float-left">
                <button
                  onClick={redirect}
                  className="py-1 px-3 bg-white text-lg rounded-lg text-black"
                  style={{ border: "1px solid black" }}
                >
                  <VisibilityIcon /> Variant List
                </button>
              </div>
            </div>
          </div>

          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    {/* <input
                      type="checkbox"
                      className="border-gray-300 rounded h-5 w-5 mr-2"
                    /> */}
                    <div className="w-16">ID</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-40">BUSINESS TYPE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-48">BUSINESS SUB TYPE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VARIANT TITLE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    <div className="w-32">VARIANT VALUE</div>
                  </TableCell>
                  <TableCell
                    style={{
                      borderRight: "1px solid #D0D0D0",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    STATUS
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    className="!text-center  !text-gray-800 !font-bold"
                  >
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data
                    .filter(
                      (item) =>
                        item.variant?.category?.title &&
                        item.variant?.category?.title
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    )
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {/* <input
                          id={row.id}
                          name={row.id}
                          type="checkbox"
                          className="border-gray-300 rounded h-5 w-5 mr-5"
                        /> */}
                          {row.id}
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {
                            row?.variant?.category?.business_type?.category
                              ?.name
                          }
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {row?.variant?.category?.business_type?.name}
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {row?.variant?.category?.title}
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {row?.value}
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            borderRight: "1px solid #D0D0D0",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          {row?.variant_storage?.category?.business_type
                            ?.category?.active_status == true ? (
                            <Active />
                          ) : (
                            <Inactive />
                          )}
                        </TableCell>
                        <TableCell
                          className="!text-center !text-[12px]"
                          style={{
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          <DeleteIcon className="!text-xl !text-red-600 !cursor-pointer" />
                          <button onClick={handleOpen}>
                            <EditIcon
                              className="!text-xl !cursor-pointer !text-green-600"
                              onClick={() => EditData(row)}
                            />
                          </button>
                        </TableCell>
                        {/* <TableCell align="center" className="text-left justify-center">
                        {row.status === null ? (
                          <Unmaped />
                        ) : row.status === 1 ? (
                          <Active />
                        ) : (
                          <Inactive />
                        )}
                      </TableCell> */}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            className="!float-right bg-white w-[100%] relative mx-auto bottom-0 right-0 py-3 border-t-2 border-gray-500 !flex-col !justify-end !items-center"
            style={{ marginRight: "0px" }}
          >
            <button onClick={nextData} className="float-right">
              <ArrowForwardIosIcon />
            </button>
            <button onClick={previousData} className="float-right">
              <ArrowBackIosIcon />
            </button>
          </div>

          {/* edit funtionality start */}
          {/* <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="!border-0 !rounded-xl">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="!text-center underline">Add Variant</div>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="gap-y-3">
                  <InputLabel id="demo-simple-select-label">Name</InputLabel>
                  <Select
                    className="!w-80 !mt-2 !text-black"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                  >
                    {
                      newCategory?.map((elem) =>
                        <MenuItem>{elem?.title}</MenuItem>
                      )
                    }


                  </Select>
                  <TextField
                    id="outlined-basic"
                    label="value"
                    variant="outlined"
                    className="!w-80 !mt-2" />
                </div>
                <div className="justify-between items-center ">
                  <Button variant="outlined" className="float-left top-3" onClick={handleClose1}>CANCEL</Button>
                  <Button variant="outlined" className="float-right top-3" onClick={createData}>CREATE VARIANT</Button>
                </div>
              </Typography>
            </Box>
          </Modal> */}
          {/* edit funtionality end */}
        </div>
      </div>
    </>
  );
};

export default VariantManagement;
